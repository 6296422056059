import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'filters-checkbox',
  templateUrl: './filters-checkbox.component.html',
  styleUrls: ['./filters-checkbox.component.scss'],
})

export class FiltersCheckboxComponent implements OnInit, OnDestroy {

  @Input() filterData: any = null;
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();

  private filterResetSub: Subscription;

  public selectedData: any[] = [];

  constructor(private _filterService: FilterService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.filterResetSub?.unsubscribe();
  }

  toggleValue(selectedValue: any) {
    selectedValue.isSelected = !selectedValue.isSelected;
    this.onSelectionChange.emit(this.filterData);
  }

}
