import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {tap} from "rxjs/operators";
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { Hotel } from '../models/hotel.class';
import * as moment from 'moment';
import { HotelRoomType, HotelRoomTypeKey } from '../enum/hotel/HotelRoomType';
import { HotelFacility, HotelFacilityName } from '../enum/hotel/HotelFacilities.enum';
import PassengerData from '../models/PassengerData.class';
import { BehaviorSubject } from 'rxjs';
import { PrivacyRequest } from '../interfaces/generic/privacy-request.interface';
import privacyContentDefault from '../../app/values/privacyContent.json';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class HotelService {

  public bookingObject: any = {
    passengers: null as PassengerData[],
    room: null as any,
    privacyContent: cloneDeep(privacyContentDefault) as PrivacyRequest[],
    errors: {}
  };

  public bookingObject$: BehaviorSubject<any> = new BehaviorSubject<any>(this.bookingObject);

  public highlightErrors: boolean = false;
  public highlightErrors$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.highlightErrors);

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      apikey: environment.hotelApiKey
    })
  };

  //TODO aggiungere cache ovunque!

  constructor(
    private _http: HttpClient,
    private commonService: CommonService
    ) { }

  // Prefetch Hotel
  public prefetchHotels(searchTerm: string): Promise<any> {
    return this._http
      .post<any>(`${environment.hotel}search/prefetch`, Hotel.getPrefetchHotelsQuery(searchTerm), this.httpOptions)
      .pipe(
        tap((response: any) => {
          if (response.error) {
            throw new Error(response.message);
          }
        })
      )
      .toPromise();
  }

  //  Ricerca Hotel
  public searchHotels(params: any): Promise<any> {
    return this._http
      .post<any>(`${environment.hotel}search/searchHotels`, Hotel.getSearchHotelsQuery(params), this.httpOptions)
      .pipe(
        tap((response: any) => {
          if (response.error) {
            throw new Error(response.message);
          }
        })
      )
      .toPromise();
  }


    //  Dettaglio Hotel
    public hotelDetails(params: any): Promise<any> {
      return this._http
        .post<any>(`${environment.hotel}search/hotelDetails`, Hotel.getDetailHotelsQuery(params), this.httpOptions)
        .pipe(
          tap((response: any) => {
            if (response.error) {
              throw new Error(response.message);
            }
          })
        )
        .toPromise();
    }

    //  Pre-Booking (quotation)
    public quotation (params: any): Promise<any> {
      return this._http
        .post<any>(`${environment.hotel}booking/preBook`, Hotel.getPreBookingHotelsQuery(params), this.httpOptions)
        .pipe(
          tap((response: any) => {
            if (response.error) {
              throw new Error(response.message);
            }
          })
        )
        .toPromise();
    }

    //  Conferma Prenotazione
    public confirm(params: any): Promise<any> {
      return this._http
        .post<any>(`${environment.hotel}booking/bookHotel`, Hotel.getBookingHotelsQuery(params), this.httpOptions)
        .pipe(
          tap((response: any) => {
            if (response.error) {
              throw new Error(response.message);
            }
          })
        )
        .toPromise();
    }

    //  Formatta un array di hotel restituito da API
    public formatHotelsForListPage(hotelsList: any[]){
      return hotelsList.map((hotel: any) => this.formatHotel(hotel));
    }

    // Formatta, mappa e normalizza un singolo hotel passato come parametro per come restituito dall'API
    public formatHotel(rowHotel: any) {
      return {
        checkIn: moment(rowHotel.checkIn, "YYYY-MM-DD").toDate(),
        checkOut: moment(rowHotel.checkOut, "YYYY-MM-DD").toDate(),
        title: rowHotel.name,
        nights: rowHotel.nights,
        distanceFromCenter: rowHotel.hotelDetails?.position?.centerDistance ? Number(rowHotel.hotelDetails?.position?.centerDistance) * 1000 : null,
        price: this.getHotelCheapestPrice(rowHotel?.rooms),
        media: rowHotel.hotelDetails?.pictures,
        services: this.formatHotelServices(rowHotel.hotelDetails?.roomsFacilities, rowHotel.hotelDetails?.hotelFacilities),
        texts: rowHotel.hotelDetails?.sheets || null,
        category: rowHotel.hotelDetails?.stars || 0,
        code: rowHotel.hotelDetails?.code,
        checkInTime: this.formatCheckInTime(rowHotel.hotelDetails?.hotelFacilities?.checkin),
        hasParking: rowHotel.hotelDetails?.hotelFacilities?.parkAuto && rowHotel.hotelDetails?.hotelFacilities?.parkAuto !== "false",
        hasTransfer: rowHotel.hotelDetails?.hotelFacilities?.shuttleApt && rowHotel.hotelDetails?.hotelFacilities?.shuttleApt !== "false",
        rooms: this.formatHotelRooms(rowHotel.rooms),
        cityCode: rowHotel.hotelDetails?.cityCode,
        cityName: rowHotel.hotelDetails?.city?.toLowerCase(),
        searchNumber: rowHotel?.searchNumber,
        position: this.getHotelPosition(rowHotel.hotelDetails),
        source: '',
        isInWishlist: false
      }
  }


  //  Restituisce cordinate geografiche dell'hotel
  private getHotelPosition(rowHotelDetails: any) {
    if(!rowHotelDetails?.latitude || !rowHotelDetails.longitude) return null;
    return {
      lt: rowHotelDetails.latitude,
      ll: rowHotelDetails.longitude,
    }
  }

  private formatCheckInTime(rawCheckInTime: string): string {
    if(rawCheckInTime?.length < 2) return null;
    if(rawCheckInTime === '--:--') return null;
    if(rawCheckInTime?.length > 5) return rawCheckInTime.substring(0,5);
    return rawCheckInTime;
  }

  public formatHotelRooms(rawRooms: any[]) {
    if(!rawRooms?.length) return [];
    return rawRooms
    .map( (room: any) => {
      return {
        title: HotelRoomType[room?.roomTypes[0].type?.toUpperCase() as HotelRoomTypeKey] || null,
        description: room.roomDescription,
        type:  room?.roomTypes[0].type,
        price: Number(room.totalPrice) || null,
        basis: room?.roomBasis,
        mealBasis: room.mealBasis,
        meal: room.mealBasis,
        code: room.agreementId,
        freeCancellationLimitDate: this.getFreeCancellationInfo(room.policies),
      }
    })
    .sort((a: any,b: any) => a.price - b.price);
  }

  //  Restituisce data limite per la cancellazione gratuita (se ancora disponibile), null (se scaduta) o 'N/A' se l'informazione non è disponibile
  getFreeCancellationInfo(policies: any) : string | Date{

    if(!policies?.length) return "N/A";

    //  Fra le policies individuo quella con importo di penale inferiore (la cui data rappreentarà la scadenza della cancellazione gratuita)
    const earliestRoomPolicy = policies
      .map( (policy: any) => { return { from: policy.from, percentage:  Number(policy.percentage) } })
      .filter( (policy: any) => policy.percentage)
      .sort((a: any, b: any) => a.percentage - b.percentage )
      .find( (first: any) => first );

    if(earliestRoomPolicy?.from?.length < 10) return "N/A";

    const limitDateString = earliestRoomPolicy.from?.substring(0,10);
    const limitDate = moment(limitDateString, 'YYYY-MM-DD');

    if(!limitDate.isValid()) return "N/A";
    if (!limitDate.isAfter(moment())) return null;
    return limitDate.toDate();
  }

  //  Formatta l'array dei servizi dell'hotel
  //  NB: la lista dei servizi comprenderà sia quelli generali della struttura (hotelFacilities) che quelli delle camere (roomFacilities)
  formatHotelServices(roomFacilities: any[], hotelFacilities: any[]){
    const allFacilities: any[] = [];

    //  formatta i servizi contenuti nell'oggetto passato come argomento
    const extractServiceFromFacilitiesObject = function(facilitiesObject: any) {
      for(const [facilityeName, availability] of Object.entries(facilitiesObject)) {
        const isAvailable = availability && availability !=="false";
        if (isAvailable && HotelFacility[facilityeName as HotelFacilityName]) {
          allFacilities.push(HotelFacility[facilityeName as HotelFacilityName]);
        }
      }
    }

    //  Invoco la funzione precedente prima per i servizi della camera e poi per quelli dell'hotel
    if(hotelFacilities)  extractServiceFromFacilitiesObject(hotelFacilities);
    if(roomFacilities) extractServiceFromFacilitiesObject(roomFacilities);
    return allFacilities;
  }

  //  Restituisce il prezzo più basso fra quello dell'array dicamere passate come argomento
  getHotelCheapestPrice(roomsList: any[]): number {
    if (!roomsList?.length) return null;

    const prices = roomsList
      .map(room => Number(room.totalPrice))
      .filter((price: number) => price && price > 0);

    let minPrice = Infinity;

    prices.forEach((price: number) => {
      if(!price) return null;
      if (price < minPrice) {
        minPrice = price;
      }
    })

    return minPrice === Infinity ? null : Math.trunc(minPrice);

  }

}
