import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements AfterViewInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject();
  private rendererListenerFn = () => {};
  private classessToCheck = ['theme','modal-opener', 'c-mosaik', 'modal', 'lightbox-close', 'icon-close', 'modal-gallery','modal-gallery__close', 'modal-gallery__image', 'lightbox-next', 'lightbox-prev', 'lightbox-container']

  @ViewChild('popupContent') popupContent: ElementRef;
  @Output() onClosePopup = new EventEmitter<boolean>();


  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,

  ) {
    this.rendererListenerFn = this._renderer.listen('window', 'mouseup', (e: Event) => {
      const checkedClass = this.classessToCheck.some(cls => (e.target as Element).classList.contains(cls))
      if (!this.popupContent.nativeElement.matches(':hover') && !checkedClass) {
        this.closePopup();
      }
    });
  }

  ngAfterViewInit(): void {
    // HACK
    document.querySelector('body').appendChild(this._elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
    this.rendererListenerFn();
  }

  public closePopup() {
    this.onClosePopup.emit(true);
  }

}
