import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[onImageLoadError]'
})
export class OnImageLoadErrorDirective {

  constructor(private el: ElementRef) {}

  @Input() onImageLoadError: string;
  
  @HostListener('error') onMouseEnter() {
    this.onImageError();
  }
  
  private onImageError() {

    const currentSrc = this.el.nativeElement?.getAttribute('src');

    if (this.onImageLoadError === currentSrc) {
      this.onImageLoadError = null;
    }

    //  Se era stata passata un'immagine alternativa da usare in caso di errore sostituisce attributo src
    if (this.onImageLoadError?.length) {
      this.el.nativeElement.setAttribute ('src', this.onImageLoadError);
      return;
    }
    
    // Altrimenti nasconde del tutto l'immagine via css
    this.el.nativeElement.style.display = 'none';

    //  Se necessario nasconde anche l'elemento genitore (potrebbe essere un container con sfondo bianco che fa da sfondo all'immagine)
    if(this.el.nativeElement.parentElement.classList.includes('hide-on-child-image-error')) {
      this.el.nativeElement.parentElement.style.display = 'none';
    }
  }

}
