import { Component, OnInit, Input } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'cruise-search-filters',
  templateUrl: './cruise-search-filters.component.html',
  styleUrls: ['./cruise-search-filters.component.scss']
})
export class CruiseSearchFiltersComponent implements OnInit {

  @Input() filterObject: any = null;
  @Input() isInsideModal: boolean = false;
  @Input() searchCount: { all: number, filtered: number } = null;

  constructor(private _filterService: FilterService) { }

  ngOnInit(): void {}

  public updateCruiseTopFilter(newCruiseTopFilterSelection: any) {  
    this.filterObject.tourOperator = newCruiseTopFilterSelection;
    this.applyFilters();
  }

  public updateShipsFilter(newCruiseShipFilterSelection: any) {  
    this.filterObject.ships = newCruiseShipFilterSelection;
    this.applyFilters();
  }

  public updateHarboursFilter(newCruiseHarbourFilterSelection: any) {  
    this.filterObject.harbours = newCruiseHarbourFilterSelection;
    this.applyFilters();
  }

  public updateDurationsFilter(newCruiseDurationFilterSelection: any) {  
    this.filterObject.durations = newCruiseDurationFilterSelection;
    this.applyFilters();
  }
  
  public updatePriceFilter(newCruisePriceFilterSelection: any) {
    this.filterObject.prices = newCruisePriceFilterSelection;
    this.applyFilters();
  }

  public updateOrderFilter(newCruiseOrderByFilterSelection: any) {
    this.filterObject.orderBy = newCruiseOrderByFilterSelection;
    this.applyFilters();
  }

  public resetAllFilters() {
    this._filterService.resetCruiseFilters();
  }

  private applyFilters() {
    this._filterService.updateCruiseFilters(this.filterObject);
  }

}
