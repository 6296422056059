/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Occupancy } from '../interfaces/generic/occupancy';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  defaultAges = [35, 35];

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  public getAgesArrayFromString(ageString: string, separator: string = ','): number[] {
    return ageString
      .split(separator)
      .filter((age) => !Number.isNaN(Number(age))) //  solo numeri validi
      .map((age) => Number(age))
      .sort((a: number, b: number) => b - a);
  }

  public getAgesArrayFromOccupancy(occupancy: Occupancy): number[] {
    return [...(occupancy.adults as number[]), ...(occupancy.children as number[])]
      .map((age) => age || 0)
      .sort((a: number, b: number) => b - a);
  }

  public getAgesStringFromOccupancy(occupancy: Occupancy): string {
    return this.getAgesArrayFromOccupancy(occupancy).join(',');
  }

  public getAgesStringFromArray(ages: number[]): string {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _occupancy = this.getOccupancyFromAgesArray(ages);
    return this.getAgesStringFromOccupancy(_occupancy);
  }

  public getOccupancyFromAgesString(ages: string): Occupancy {
    const agesArray = this.getAgesArrayFromString(ages);
    return this.getOccupancyFromAgesArray(agesArray);
  }

  public getOccupancyFromAgesArray(agesArray: number[]): Occupancy {
    const ages = agesArray.sort((a: number, b: number) => b - a);
    return {
      adults: ages.filter((age: number) => age >= 18),
      children: ages.filter((age: number) => age < 18),
      guests: ages.length,
    };
  }

  public getDefaultOccupancy(): Occupancy {
    return this.getOccupancyFromAgesArray(this.defaultAges);
  }

    //  Questo metodo prende in ingresso due occupancies (in uno qualsiasi dei tre formati utilizzati dall'applicazione) restituendo TRUE se sono identiche e FALSE in caso contrario
  //  gli argomenti a e b possono essere di tipo Occupancy oppure un array avente come primo elemento le età e come secondo elemento il formato come tipo di dato ('array' | 'string'). Esempi:
  //  - ([ [35,32,12], 'array' ], [ '12,32,35', 'string' ])
  //  - (occupancy, [ [35,35], 'array' ])
  //  - ([ ["12,23,54", 'string'], occupancy ])
  
  public areOccupancyEquals(a: any, b: any): boolean {
    //  Normalizzazione del dato: le età vengono trasformate in array a prescindere dal formato nel quale sono state passate
    const occupancies =  [a,b].map( (occ: any[]) => {
      const [values, type] = Array.isArray(occ) ? occ : [occ,'occupany'];
      if (type === 'string') {
        return this.getAgesArrayFromString(values);
      }
      else if (type === 'array'){
        return values.sort((a: number, b: number) => b - a);
      }
      else {
        return this.getAgesArrayFromOccupancy(values);
      }
    });
    const [occupancy1, occupancy2] = occupancies;
    //  Se gli array non hanno le stesse dimensioni evidentemente le età non coincidono
    if(occupancy1?.length !== occupancy2?.length) return false;
    //  Si confrontatano, uno per uno, gli elementi dei due array e si verifica che siano sempre uguali
    for(let guestIndex=0; guestIndex < occupancy1.length; guestIndex++) {
      if(occupancy1[guestIndex] !== occupancy2[guestIndex]) {
        return false;
      }
    }
    //  Non avendo riscontrato eccezioni le età sono uguali
    return true; 
  }
  
}
