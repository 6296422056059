import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MobileService } from 'src/app/services/mobile.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'skeleton-solutions-nav',
  templateUrl: './skeleton-solutions-nav.component.html',
  styleUrls: ['./skeleton-solutions-nav.component.scss']
})
export class SkeletonSolutionsNavComponent implements OnInit {
  public isMobile: boolean;
  private unsubscribe$ = new Subject;
  
  constructor(
    private _mobileService: MobileService,
    ) { }

    ngOnInit(): void {
      //  Gestione Vista Mobile
      this._mobileService.mobileView$.pipe(takeUntil(this.unsubscribe$)).subscribe((isMobile) => {
        this.isMobile = isMobile;
      });
  }
  
  

}
