import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal.service';
import delayAction from 'src/app/utils/delayAction';
import { ModalsTypologies } from 'src/app/enum/generic/ModalsTypologies';

@Component({
  selector: 'modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss']
})
export class ModalGenericComponent implements OnInit {

  @Input() modalSrc: any;
  @Output() modalResult = new EventEmitter();

  footerButtons: any[] = [];

  public modalsTypologies = ModalsTypologies;

  constructor( public activeModal: NgbActiveModal,
               private _modalService: ModalService) { }

    ngOnInit(): void {
      this._modalService.onModalOpen();

      if (this.modalSrc.additionalOptions.modalButtons) {
        this.handleModalButtons(this.modalSrc.additionalOptions.modalButtons);
      }

    }

    private handleModalButtons(rawButtons: any) {
      
      this.footerButtons = rawButtons.filter( (rawButton: any) => {
        return rawButton.location === "modalFooter";
      });

    }

    public onClickOnButton(button: any) {
      if (button.callback) {
        button.callback();        
      } else if (button.type === 'confirm') {
        this.onModalResult( { isConfirmed: true }, button.keepModalOpen)
      } else if (button.type === 'cancel') {
        this.onModalResult( { isCancelled: true }, button.keepModalOpen)
      }
    }
      
    public onModalResult(modalResult: any, keepModalOpen: boolean = false) {
      this.modalResult.emit(modalResult);
      if(keepModalOpen) return;
      this.closeModal();
    }
    
    public closeModal() {
      delayAction( ( () => this.activeModal.dismiss()).bind(this) , 50)
    }

    ngOnDestroy(): void {
      this._modalService.onModalClosed();
    }

}
