import { Component, OnInit } from '@angular/core';
import agency from '../../../../values/agency-info.json';

@Component({
  selector: 'c-newsletter',
  templateUrl: './c-newsletter.component.html',
  styleUrls: ['./c-newsletter.component.scss']
})
export class CNewsletterComponent implements OnInit {
  agency = agency;

  constructor() { }

  ngOnInit(): void {
  }

}
