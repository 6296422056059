import { Pipe, PipeTransform } from '@angular/core';
import { Cruise } from 'src/app/models/Cruise.class';
import {Holiday} from "../../models/Holiday.class";

@Pipe({
  name: 'searchFiltersCruise'
})
export class SearchFiltersCruisePipe implements PipeTransform {

  transform(cruisesOriginal: Cruise[], filterObject: any): Cruise[] {

    const filteredCruises = cruisesOriginal.filter( (cruise: Cruise) => {

      //  TourOperator Filter
      if(filterObject?.tourOperator) {
        const selectedTourOperatorValues = filterObject.tourOperator?.availableValues
        ?.filter( (valObj: any) => valObj.isSelected )
        ?.map( (valObj: any) => valObj.value )

        if (selectedTourOperatorValues.length) {
          if (!selectedTourOperatorValues.includes(cruise.tourOperator?.name)) {
            return false;
          }
        }
      }

      //  Ships Filter
      if (filterObject?.ships) {
        const selectedShipsValues = filterObject?.ships?.availableValues
        ?.filter( (valObj: any) => valObj.isSelected )
        ?.map( (valObj: any) => valObj.value )

        if (selectedShipsValues.length) {
          if (!selectedShipsValues.includes(cruise.ship?.name)) {
            return false;
          }
        }
      }

      //  Harbours Filter
      if (filterObject?.harbours) {
        const selectedHarboursValues = filterObject?.harbours?.availableValues
        ?.filter( (valObj: any) => valObj.isSelected )
        ?.map( (valObj: any) => valObj.value )

        if (selectedHarboursValues.length) {
          if (!selectedHarboursValues.includes(cruise.departureHarbour?.name)) {
            return false;
          }
        }
      }

      //  Durations Filter
      if (filterObject?.duration) {
        const selectedDurationsValues = filterObject?.duration?.availableValues
        ?.filter( (valObj: any) => valObj.isSelected )
        ?.map( (valObj: any) => valObj.value )

        if (selectedDurationsValues.length) {
          if (!selectedDurationsValues.includes(cruise?.days)) {
            return false;
          }
        }
      }

      //  Price Filter
      if (filterObject?.prices) {
        const { minPriceSelected, maxPriceSelected } = filterObject.prices;
        const cruiseBestPrice = cruise.bestSolution?.mkPriceCp;
        if (!cruiseBestPrice || cruiseBestPrice < minPriceSelected || cruiseBestPrice > maxPriceSelected) {
          return false;
        }
      }

      //  Se si arriva qui tutti i filtri sono stati soddisfatti: restituisco true
      return true;

    });

    //  OrderBy
    const selectedOrder = filterObject.orderBy?.find( (option: any) => option.isSelected).value as 'priceAsc' | 'priceDesc' | 'featured' || 'featured';
    filteredCruises.sort( (cruiseA: Cruise, cruiseB: Cruise) => {
      if (selectedOrder === 'priceDesc' ) return cruiseB.bestSolution.mkPriceCp - cruiseA.bestSolution.mkPriceCp;
      return cruiseA.bestSolution.mkPriceCp - cruiseB.bestSolution.mkPriceCp;
    });

    if(selectedOrder === 'featured') {
      filteredCruises.sort( (cruiseA: Cruise, holidayB: Cruise) => cruiseA.isCustom ? -1 : 1 );
    }

    return filteredCruises;

    }

}
