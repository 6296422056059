export const environment = {
    production: true,
    apiurl: 'https://api.offertetouroperator.com',
    api2url: 'https://api2.offertetouroperator.com',
    site: 'https://api2.offertetouroperator.com/oto-api/generic/nauth/',
    apiKey: 'b79368ab0a61f88d2e16d9683a0ac8da654cf7a0',
    prefetch: 'https://api2.offertetouroperator.com/oto-api/prefetch/',
    holiday: 'https://api2.offertetouroperator.com/oto-api/holiday/nauth',
    destinationsImagesUrl: 'https://www.offertetouroperator.com/images/destinazione/list_images.php',
    hotel: 'https://api2.offertetouroperator.com/hotel-service/',
    hotelApiKey: 'a438a691-4f0e-4e53-b926-ed0e8faec3a5',
    wtiHubcoreApiProxy: 'https://core2.offertetouroperator.com/~wtg-hubcore-proxy/dist/index.php/'
  };
