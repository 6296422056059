import { Injectable } from '@angular/core';
import CruiseMapping, { CruiseMappingHarbour, CruiseMappingItinerary, CruiseMappingShip } from '../interfaces/cruise/cruiseMapping.interface';
import HolidayMapping, { HolidayMappingDestination, HolidayMappingTop, HolidayMappingTransport } from '../interfaces/holiday/holidayMapping.interface';
import {shareReplay} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { cloneDeep } from 'lodash';
import { environment } from 'src/environments/environment';
import { HolidayMappingAirport, HolidayMappingBus, HolidayMappingHarbour, HolidayMappingTrain } from '../interfaces/holiday/holidayMapping.interface';
import { HolidayEngineDeparturePlaces } from '../interfaces/holiday/holidayEngine.interface';

@Injectable({
  providedIn: 'root'
})

export class MappingService {

  mappingsApi: string = `${environment.apiurl}/index.php/`;
  destinationsImagesUrl: string = environment.destinationsImagesUrl;

  private mappingHoliday: HolidayMapping = null!;
  public mappingHoliday$: BehaviorSubject<HolidayMapping> = new BehaviorSubject(this.mappingHoliday);

  private mappingCruise: CruiseMapping = null!;
  public mappingCruise$: BehaviorSubject<CruiseMapping> = new BehaviorSubject(this.mappingCruise);

  private httpCache: Map<string, Observable<any>> = new Map<string, Observable<any>>();

  private static instance: MappingService;

  constructor(private _http: HttpClient) {
    MappingService.instance = this;
  }

  public static getInstance(): MappingService {
    return MappingService.instance;
  }
  
  //  Effettua chiamata per ottenere mappingHolidays
  //  NB il metodo viene richiamato all'onInit dell'appComponent, che in seguito passerà il risultato al metodo 'setHolidayMapping' per l'effettivo salvatoggio nella variabile globale
  public getMappingHoliday(apiKey: string): Promise<HolidayMapping> {
    const api = `${this.mappingsApi}holiday/${apiKey}/mapping/all`;
    
    let cachedApi = this.httpCache.get(api);

    if (!cachedApi) {
      cachedApi = this._http.get<any>(api).pipe(shareReplay({ bufferSize: 1, refCount: true}));
      this.httpCache.set(api, cachedApi);
    }
    return cachedApi.toPromise();
  }

  //  Setta mappingHolidays nella variabile globale
  public setHolidayMapping(mappings: HolidayMapping) {
    this.mappingHoliday = mappings;
    this.mappingHoliday$.next(this.mappingHoliday);
  }

  //  Effettua chiamata per ottenere mappingCruise
  //  NB il metodo viene richiamato all'onInit dell'appComponent, che in seguito passerà il risultato al metodo 'setCruiseMapping' per l'effettivo salvatoggio nella variabile globale
  public getMappingCruise(apiKey: string): Promise<CruiseMapping> {
    const api = `${this.mappingsApi}cruise/${apiKey}/mapping/all`;
    let cachedApi = this.httpCache.get(api);
    if (!cachedApi) {
      cachedApi = this._http.get<any>(api).pipe(shareReplay({ bufferSize: 1, refCount: true}));
      this.httpCache.set(api, cachedApi);
    }
    return cachedApi.toPromise();
  }

  //  Setta mappingCruise nella variabile globale
  public setCruiseMapping(mappings: CruiseMapping) {
    this.mappingCruise = mappings;
    this.mappingCruise$.next(this.mappingCruise);
  }

  //  I metodi qui sotto, passato l'id di un departure place, lo cercano all'interno dei mappings, fra i departurePlaces di un determinato tipo.
  //  Se lo trovano restituiscono l'oggetto stesso, in caso contrario undefined
  private getAirportMappingObject = (id: number): HolidayMappingAirport => this.mappingHoliday?.airports?.objects?.[id];
  private getHarbourMappingObject = (id: number): HolidayMappingHarbour => this.mappingHoliday?.harbours?.objects?.[id];
  private getTrainMappingObject = (id: number): HolidayMappingTrain => this.mappingHoliday?.trains?.objects?.[id];
  private getBusMappingObject = (id: number): HolidayMappingBus => this.mappingHoliday?.destinations?.objects?.[id];

  //  Passato l'id di un departurePlace restituisce un oggetto avente come proprietà departurePlaceTypology il tipo di place e come proprietà departurePlace l'oggetto departurePlace stesso
  public getDeparturePlaceObj(id: number): { departurePlaceTypology: 'airports' | 'harbours' | 'trains' | 'buses', departurePlace: HolidayMappingAirport | HolidayMappingHarbour | HolidayMappingBus | HolidayMappingTrain } {
    
    const resultAirport: HolidayMappingAirport = this.getAirportMappingObject(id);
    const resultHarbour: HolidayMappingHarbour = this.getHarbourMappingObject(id);
    const resultTrain: HolidayMappingTrain = this.getTrainMappingObject(id);
    const resultBus: HolidayMappingBus = this.getBusMappingObject(id);

    if (resultAirport) return { 'departurePlaceTypology' : 'airports', departurePlace: cloneDeep(resultAirport) };
    if (resultHarbour) return { 'departurePlaceTypology' : 'harbours', departurePlace: cloneDeep(resultHarbour) };
    if (resultTrain) return { 'departurePlaceTypology' : 'trains', departurePlace: cloneDeep(resultTrain) };
    if (resultBus) return { 'departurePlaceTypology' : 'buses', departurePlace: cloneDeep(resultBus) };

    return null;
  }
  
  //  Passato l'id di un departurePlace restituisce l'oggetto corrispondente proveniente dai mappings
  getDeparturePlaceById(id: number): HolidayMappingTransport {
    return this.getDeparturePlaceObj(id)?.departurePlace || null;
  }

  //  Passata come argomento una lista di id corrispondente a tutti i departurePlace restituisce un oggetto di tipo HolidayEngineDeparturePlaces (che sarà poi utilizzato dall'engine)
  public mapDeparturePlaces(departurePlaces: string[]): HolidayEngineDeparturePlaces {
    const mappedDeparturePlaces: HolidayEngineDeparturePlaces = {};
    
    departurePlaces.forEach( (departurePlaceId: string) => {
      if(!departurePlaceId || departurePlaceId === 'null') { //  Casistica solo soggiorno
        mappedDeparturePlaces['noTransport'] = [{ Id: null, Name: 'Solo Soggiorno' }];
        return;
      }
      //  Mi faccio restituire la tipologia del punto di partenza ed il punto di partenza stesos dall'apposito metodo
      //  NB: departurePlaceTypology può avere come valore 'airports' | 'harbours' | 'trains' | | 'noTransport' 'buses'...
      //  ...e questo sarà anche la key dell'oggetto mappedDeparturePlaces che conterrà l'array con i departurePlaces
      const { departurePlace, departurePlaceTypology } = this.getDeparturePlaceObj(Number(departurePlaceId));
      //  Se l'oggetto mappedDeparturePlaces non contiene ancora la proprietà con nome corrispondente a departurePlaceTypology la creo assegnandole come valore un array vuoto
      if (!mappedDeparturePlaces[departurePlaceTypology]?.length) { 
        mappedDeparturePlaces[departurePlaceTypology] = [];
      }
      //  Nella proprietà avente come nome la departurePlaceTypology pusho l'oggetto departurePlace
      mappedDeparturePlaces[departurePlaceTypology].push(departurePlace as any);
    });

    return mappedDeparturePlaces;

  }

  //  Restituisce tutte le immagini delle destinazioni prelevandole dal cdn
  public getDestinationsImages(): Promise<any> {    
    const api = this.destinationsImagesUrl;
    let cachedApi = this.httpCache.get(api);    
    if (!cachedApi) {
      cachedApi = this._http.get<any>(api).pipe(shareReplay({ bufferSize: 1, refCount: true}));
      this.httpCache.set(api, cachedApi);
    }
    return cachedApi.toPromise();
  }

  //  Restituisce un oggetto destinazione passandone l'Id
  getDestinationById(id: number): HolidayMappingDestination {    
    const destination: HolidayMappingDestination = this.mappingHoliday?.destinations?.objects?.[id];
    return destination ? cloneDeep(destination) : null;
  }

  //  Restituisce un oggetto tourOperator passandone l'Id
  getTourOperatorById(id: number): HolidayMappingTop {
    const tourOperator: HolidayMappingTop = this.mappingHoliday?.tops?.objects?.[id];
    return tourOperator ? cloneDeep(tourOperator) : null;
  }

  //  Passato come parametro l'id di un ITINERARIO, restituisce l'oggetto corrispondente proveniente dal mapping
  public getCruiseItineraryObjectById(id: number): CruiseMappingItinerary {
    const matching = this.mappingCruise?.itineraries?.objects[id];
    if(!matching) {
      console.warn("Impossibile mappare itinerario con id ", id);
      return null;
    }
    return cloneDeep(matching);
  }

  //  Passato come parametro l'id di un PORTO, restituisce l'oggetto corrispondente proveniente dal mapping
  public getHarbourObjectById(id: number): CruiseMappingHarbour {
    const matching = this.mappingCruise?.harbours?.objects[id];
    if(!matching) {
      console.warn("Impossibile mappare porto con id ", id);
      return null;
    }
    return cloneDeep(matching);
  }

  //  Passato come parametro l'id di una NAVE, restituisce l'oggetto corrispondente proveniente dal mapping
  public getShipObjectById(id: number): CruiseMappingShip {
    const matching = this.mappingCruise?.ships?.objects[id];
    if(!matching) {
      console.warn("Impossibile mappare nave con id ", id);
      return null;
    }
    return cloneDeep(matching);
  }

}
