import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HoverPreloadStrategy } from 'ngx-hover-preload';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'vacanze',
    data: { name: 'Vacanze' },
    loadChildren: () => import('./modules/booking-flow/holiday/holiday.module').then((m) => m.HolidayModule),
  },
  {
    path: 'hotel',
    data: { name: 'Hotel' },
    loadChildren: () => import('./modules/booking-flow/hotel/hotel.module').then((m) => m.HotelModule),
  },
  {
    path: 'crociere',
    data: { name: 'Crociere' },
    loadChildren: () => import('./modules/booking-flow/cruise/cruise.module').then((m) => m.CruiseModule),
  },
  {
    path: 'incoming',
    data: { name: 'Incoming' },
    loadChildren: () => import('./modules/booking-flow/incoming/incoming.module').then((m) => m.IncomingModule),
  },
  {
    data: { name: 'merlinx', canNavigate: true },
    path: 'm',
    loadChildren: () => import('./modules/merlinx/merlinx.module').then(m => m.MerlinxLandingPageModule)
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: HoverPreloadStrategy, anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
