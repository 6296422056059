import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { IEmailParams } from 'src/app/interfaces/generic/iemail-params';
import { MailerService } from 'src/app/services/mailer.service';
import agency from '../../../../values/agency-info.json';

@Component({
  selector: 'form-contact',
  templateUrl: './form-contact.component.html',
  styleUrls: ['./form-contact.component.scss']
})
export class FormContactComponent implements OnInit {

  private agency = agency;

  userName: string;
  userSurname: string;
  userEmail: string;
  userPhone: string;
  // title: string;
  message: string;
  sendingEmail: boolean;

  @Input() messageToShow : string;

  public highlightErrors: boolean = false;
  public alreadySentMail: boolean = false;
  public emailSent: boolean = false;

  date = new Date();

  constructor(private _mailerService:MailerService) { }

  ngOnInit(): void {
  }

  //  PREVEDERE FORM SUBMIT AL POSTO DI QUESTA LOGICA

  public getBodyMessage(){
    const bodyMessage = `<strong>${this.userName}&nbsp;${this.userSurname}</strong> ha inviato una richiesta tramite il 
                        form contattaci in data <strong>${moment(this.date).format('DD/MM/YYYY')}</strong>
                        alle ore: <strong>${moment(this.date).format('hh:mm')}</strong>.  
                        <p>Messaggio Utente:</p> <p>"${this.message}"</p>
                        <p>Dati per ricontattare l'utente:</p> <p>Email: ${this.userEmail} Telefono: ${this.userPhone}</p>`
    return bodyMessage
  }

  public resetFormFields(){
    this.userName = '';
    this.userSurname = '';
    this.userEmail = '';
    this.userPhone = '';
    this.message = '';
  }

  public resetForm(){
    this.alreadySentMail != this.alreadySentMail;
  }

  public getMailObject(){

    if(this.sendingEmail){
      return
    }

    if(!this.userName.length || !this.userSurname || !this.userEmail || !this.userPhone || !this.message ){
      this.highlightErrors = true;
      return
    }

    this.highlightErrors = false;
    this.sendingEmail = true;

    const mailObj: IEmailParams = {
      title: 'Richiesta supporto - Viaggi e Cultura',
      body: this.getBodyMessage(),
      from: this.userEmail,
      to: this.agency.email,
    };

    this._mailerService.sendMail(mailObj)
    .then( (res)=> {
                    this.resetFormFields(),
                    this.emailSent = true;
                    })
    .catch((err) => console.log(err))
    .finally(()=>  {
                    this.sendingEmail = false,
                    this.alreadySentMail = true;
                    });
  }
}

