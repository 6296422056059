import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  
  private unsubscribe$ = new Subject;  

  private isLoadingSub: Subscription;
  public isLoading: boolean;

  private loadingMessageSub: Subscription;
  public loadingMessage: string;

  constructor(private _loaderService: LoaderService) {}

  ngOnInit(): void {

    this.isLoadingSub = this._loaderService.loadingStatus$.pipe(takeUntil(this.unsubscribe$)).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });

    this.loadingMessageSub = this._loaderService.loadingMessage$.pipe(takeUntil(this.unsubscribe$)).subscribe((loadingMessage) => {
      this.loadingMessage = loadingMessage;
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
