import { Injectable } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ModalsTypologies } from '../enum/generic/ModalsTypologies';

@Injectable({
  providedIn: 'root'
})

export class FilterService {
  
  public cruiseFilters: any = {}
  public cruiseFilters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public holidayFilters: any = {}
  public holidayFilters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public hotelFilters: any = {}
  public hotelFilters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {}

  //  Filtri Hotel

  public initializeHotelFilters(filterObject: any) {
    for( const [filterName, filterData ] of Object.entries(filterObject) ) {
      
      //  Inizializzazione Slider dei Prezzi
      if(filterName === 'prices') {
        this.hotelFilters['prices'] = this.initializePriceFilter(filterData);
        continue;
      }

      if(filterName === 'orderBy') {
        this.hotelFilters['orderBy'] = cloneDeep(filterData);
        continue;
      }

      //  Inizializzazione di tutti gli altri filtri (che prevedono lo stesso funzionamento)
      this.hotelFilters[filterName] = {
        availableValues: (filterData as any[]).map( (valueOriginal: any ) => {
          return {
            value: valueOriginal.value || valueOriginal,
            label: valueOriginal.label || null,
            isSelected: false
          }
         }),
      }
    }
    
    this.hotelFilters$.next(this.hotelFilters);

  }

  public updateHotelFilters(filterObject: any) {
    this.hotelFilters = filterObject;
    this.hotelFilters$.next(this.hotelFilters);
  }

  public resetHotelFilters() {
    for( const filterName of Object.keys(this.hotelFilters) ) {
      
      //  reset Slider dei Prezzi
      if(filterName === 'prices') {        
        this.hotelFilters['prices'] = cloneDeep(this.initializePriceFilter(this.hotelFilters['prices']));              
        continue;
      }
      
      //  Si skippa reset OrderBy
      if(filterName === 'orderBy') {
        continue;
      }

      //  Reset di tutti gli altri filtri (che prevedono lo stesso funzionamento)
      this.hotelFilters[filterName].availableValues.forEach( (value: any) => {
        value.isSelected = false;
      });
      
    }

    this.hotelFilters$.next(this.hotelFilters);
  }

  //  Filtri Holidays

  public initializeHolidayFilters(filterObject: any) {
    for( const [filterName, filterData ] of Object.entries(filterObject) ) {
      
      //  Inizializzazione Slider dei Prezzi
      if(filterName === 'prices') {
        this.holidayFilters['prices'] = this.initializePriceFilter(filterData);
        continue;
      }

      if(filterName === 'orderBy') {
        this.holidayFilters['orderBy'] = cloneDeep(filterData);
        continue;
      }

      //  Inizializzazione di tutti gli altri filtri (che prevedono lo stesso funzionamento)
      this.holidayFilters[filterName] = {
        availableValues: (filterData as any[]).map( (valueOriginal: any ) => {
          return {
            value: valueOriginal.value || valueOriginal,
            label: valueOriginal.label || null,
            isSelected: false
          }
         }),
      }
    }

    this.holidayFilters$.next(this.holidayFilters);

  }

  public updateHolidayFilters(filterObject: any) {
    this.holidayFilters = filterObject;
    this.holidayFilters$.next(this.holidayFilters);
  }

  public resetHolidayFilters() {
    for( const filterName of Object.keys(this.holidayFilters) ) {
      
      //  reset Slider dei Prezzi
      if(filterName === 'prices') {       
        this.holidayFilters['prices'] = cloneDeep(this.initializePriceFilter(this.holidayFilters['prices']));      
        continue;
      }

      //  Si skippa reset OrderBy
      if(filterName === 'orderBy') {        
        continue;
      }
      
      //  Reset di tutti gli altri filtri (che prevedono lo stesso funzionamento)
      this.holidayFilters[filterName].availableValues.forEach( (value: any) => {
        value.isSelected = false;
      });
      
    }

    this.holidayFilters$.next(this.holidayFilters);
  }


  //  Filtri Cruises

  public initializeCruiseFilters(filterObject: any) {
    for( const [filterName, filterData ] of Object.entries(filterObject) ) {
      
      //  Inizializzazione Slider dei Prezzi
      if(filterName === 'prices') {
        this.cruiseFilters['prices'] = this.initializePriceFilter(filterData);
        continue;
      }

      if(filterName === 'orderBy') {
        this.cruiseFilters['orderBy'] = cloneDeep(filterData);
        continue;
      }

      //  Inizializzazione di tutti gli altri filtri (che prevedono lo stesso funzionamento)
      this.cruiseFilters[filterName] = {
        availableValues: (filterData as any[]).map( (valueOriginal: any ) => {
          return {
            value: valueOriginal.value || valueOriginal,
            label: valueOriginal.label || null,
            isSelected: false
          }
         }),
      }
    }

    this.cruiseFilters$.next(this.cruiseFilters);

  }

  public updateCruiseFilters(filterObject: any) {
    this.cruiseFilters = filterObject;
    this.cruiseFilters$.next(this.cruiseFilters);
  }

  public resetCruiseFilters() {
    for( const filterName of Object.keys(this.cruiseFilters) ) {
      
      //  reset Slider dei Prezzi
      if(filterName === 'prices') {        
        this.cruiseFilters['prices'] = cloneDeep(this.initializePriceFilter(this.cruiseFilters['prices']));        
        continue;
      }

      //  Si skippa reset OrderBy
      if(filterName === 'orderBy') {
        continue;
      }
      
      //  Inizializzazione di tutti gli altri filtri (che prevedono lo stesso funzionamento)
      this.cruiseFilters[filterName].availableValues.forEach( (value: any) => {
        value.isSelected = false;
      });
      
    }

    this.cruiseFilters$.next(this.cruiseFilters);
  }

  //  Common

  private initializePriceFilter(prices: any)  {
    const { minPriceAbsolute, maxPriceAbsolute } = prices as any;
    return {
      minPriceAbsolute, maxPriceAbsolute,
      minPriceSelected: minPriceAbsolute,
      maxPriceSelected: maxPriceAbsolute
    }
  }

  public getFiltersModalOptions(modalTypology: ModalsTypologies, filterObject: any, searchCount: any = null ) {

    const modalConfig = {
      ariaLabelledBy: 'modal-filters',
      size: 'lg',
      centered: true,
    };
    
    const modalButtons = [] as any;
    
    const confirmButton = {
      // callback: () => console.log("this is just a try"),
      type: 'confirm',
      show: true,
      text: 'Fatto',
      location: 'modalFooter',
      classNames: 'w-100 text-white btn c-button t-secondary'
    }

    modalButtons.push(confirmButton);
    
    const data = {filterObject, searchCount};
    const additionalOptions = {modalButtons, modalTitle: 'Filtra i Risultati'};
    const modalSrc = { data, modalTypology, additionalOptions}    

    return {modalConfig, modalSrc};
  }

}
