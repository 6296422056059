import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFiltersHotel'
})
export class SearchFiltersHotelPipe implements PipeTransform {

  transform( hotelsOriginal: any, filterObject: any ): any[] {

    const filteredHotels = hotelsOriginal.filter( (hotel: any) => {
      
      //  Category Filter
      if (filterObject?.category) {
        const selectedCategoryValues = filterObject?.category?.availableValues
        ?.filter( (valObj: any) => valObj.isSelected )
        ?.map( (valObj: any) => valObj.value )
        
        if (selectedCategoryValues.length) {
          const hotelStars = Number(hotel.category);
          if (!hotelStars || !selectedCategoryValues.includes(hotelStars)) {
            return false;
          }
        }
      }

      //  Facilities Filter
      if (filterObject?.facilities) {
        const selectedFacilitiesValues = filterObject?.facilities?.availableValues
        ?.filter( (valObj: any) => valObj.isSelected )
        ?.map( (valObj: any) => valObj.value )

        if (selectedFacilitiesValues.length) {        
          for ( const selectedFacility of selectedFacilitiesValues) {
            if(!hotel.services.includes(selectedFacility)) {
              return false;
            }
          }        
        }
      }
      
      //  Distance Filter
      if (filterObject?.distance) {
        const maxAllowedDistance = filterObject.distance?.availableValues
        ?.filter( (valObj: any) => valObj.isSelected )
        ?.map( (valObj: any) => valObj.value )
        .reduce( (distanceA: number, distanceB: number) => distanceA > distanceB ? distanceA : distanceB , 0 )
        
        if ( maxAllowedDistance > 0 ) {
          if (!hotel.distanceFromCenter || hotel.distanceFromCenter > maxAllowedDistance ) {
            return false;
          }
        }
      }

      //  Price Filter
      if (filterObject?.prices) {
        const { minPriceSelected, maxPriceSelected } = filterObject.prices;
        const hotelBestPrice = hotel.price || null;      
        if ( !hotelBestPrice || hotelBestPrice < minPriceSelected || hotelBestPrice > maxPriceSelected) {
          return false;
        }
      }

      //  Se si arriva qui tutti i filtri sono stati soddisfatti: restituisco true
      return true;
    })

    //  OrderBy    
    const selectedOrder = filterObject?.orderBy?.find( (option: any) => option.isSelected).value as 'priceAsc' | 'priceDesc' | 'distance' || 'priceAsc';
    filteredHotels.sort( (hotelA: any, hotelB: any) => {
      if (selectedOrder === 'priceAsc' ) return hotelA.price - hotelB.price;
      if (selectedOrder === 'priceDesc' ) return hotelB.price - hotelA.price;
      if (selectedOrder === 'distance' ) {
        if (!hotelA.distanceFromCenter) return 1; //  Gli hotel per i quali non è precisata una disanza dal centro saranno gli ultimi
        return hotelA.distanceFromCenter - hotelB.distanceFromCenter;
      }
      return 0;
    })

    return filteredHotels;
    
  }

}
