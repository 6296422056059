import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Occupancy } from 'src/app/interfaces/generic/occupancy';

@Component({
  selector: 'guests-selector',
  templateUrl: './guests-selector.component.html',
  styleUrls: ['./guests-selector.component.scss'],
})
export class GuestsSelectorComponent implements OnInit, OnChanges {

  @Input() oldOccupancy: Occupancy;

  @Input() dynamicUpdate: boolean = false;
  @Output() onNewSelectedOccupancy = new EventEmitter();

  newOccupancy: Occupancy;
  maxGuests: number = 5;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.oldOccupancy && this.oldOccupancy) {
      this.newOccupancy = cloneDeep(this.oldOccupancy);
    }
  }

  ngOnInit(): void {}

  //  Aggiunge un adulto alla occupancy
  addAdult() {
    this.newOccupancy.adults.push(35);
    if(!this.dynamicUpdate) return;
    this.confirmNewOccupancy();
  }

  //  Rimuove un adulto dalla occupancy
  removeAdult(){
    if(this.newOccupancy.adults.length <= 1) return;
    this.newOccupancy.adults.pop();
    if(!this.dynamicUpdate) return;
    this.confirmNewOccupancy();
  }

  //  Aggiunge un bambino alla occupancy
  addChild() {
    this.newOccupancy.children.push(0);
    if(!this.dynamicUpdate) return;
    this.confirmNewOccupancy();
  }

  //  Rimuove un bambino dalla occupancy
  removeChild() {
    if(this.newOccupancy.children.length < 1) return;
    this.newOccupancy.children.pop();
    if(!this.dynamicUpdate) return;
    this.confirmNewOccupancy();
  }

  trackByIndex(index: any) {
    return index;
  }

  public onChildAgeChange() {
    if(!this.dynamicUpdate) return;
    this.confirmNewOccupancy();
  }

  //  Conferma la occupancy selezionata
  public confirmNewOccupancy() {
    this.newOccupancy.guests = this.newOccupancy?.adults?.length + this.newOccupancy?.children?.length;    
    this.newOccupancy.children = this.newOccupancy.children.map( (age: string | number) => Number(age) );
    this.onNewSelectedOccupancy.emit( { newOccupancy: this.newOccupancy } );
  }

}
