import { Pipe, PipeTransform } from '@angular/core';
import CruiseMapping, { CruiseMappingHarbour } from '../interfaces/cruise/cruiseMapping.interface';
import { MappingService } from '../services/mapping.service';

@Pipe({
  name: 'harbourById'
})

export class HarbourByIdPipe implements PipeTransform {

  constructor(private _mappingService: MappingService) {}
  
  transform(harbourIdsString: string): string {
    
    if(!harbourIdsString?.length) return 'Qualsiasi Porto';

    const harbourIds = harbourIdsString
      .split(',')
      .map( (harbourId: string) => Number(harbourId) )
      .filter( (harbourId: number) => harbourId);

    if(!harbourIds?.length) return 'Qualsiasi Porto';    

    let resultString = this._mappingService.getHarbourObjectById(harbourIds[0])?.Name;

    if ( harbourIds.length === 2 ) {
      resultString += `(e un altro porto)`
    } else if ( harbourIds.length > 2 ) {
      resultString += `(e altri ${ harbourIds.length - 1 } porti)`
    }

    return resultString?.replace('(Italia)','')?.trim() || 'Qualsiasi Porto';
  }
}
