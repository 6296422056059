import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export class DateConverter {

  public static jsToNgbDate(jsDate: Date): NgbDate {
    const year = jsDate.getFullYear();
    const month = jsDate.getMonth();
    const day = jsDate.getDate();
    return new NgbDate(year, month + 1, day);
  }
  
   public static ngbToJsDate(ngbDate: NgbDate): Date {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }
}

