import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'skeleton-detail-gallery',
  templateUrl: './skeleton-detail-gallery.component.html',
  styleUrls: ['./skeleton-detail-gallery.component.scss']
})
export class SkeletonDetailGalleryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
