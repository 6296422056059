import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'any-doubt-box',
  templateUrl: './any-doubt-box.component.html',
  styleUrls: ['./any-doubt-box.component.scss']
})
export class AnyDoubtBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
