import { Pipe, PipeTransform } from '@angular/core';
import { MappingService } from '../services/mapping.service';

@Pipe({
  name: 'departurePlaceById'
})
export class DeparturePlaceByIdPipe implements PipeTransform {

  constructor( private _mappingService: MappingService ) {}

  transform(departurePlaceId: number | string, fieldToReturn: 'Name' | 'type' ): unknown {
    departurePlaceId = Number(departurePlaceId) || null;
    const departurePlaceObj = this._mappingService.getDeparturePlaceObj(departurePlaceId);
    if (!departurePlaceObj) return null;
    if (fieldToReturn === 'type') return departurePlaceObj.departurePlaceTypology;
    if (fieldToReturn === 'Name') return departurePlaceObj.departurePlace.Name;
    if (!fieldToReturn) return departurePlaceObj;
    return departurePlaceObj.departurePlace[fieldToReturn];
  }

}
