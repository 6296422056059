import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { HotelService } from 'src/app/services/hotel.service';

@Component({
  selector: 'engine-hotel-destination',
  templateUrl: './engine-hotel-destination.component.html',
  styleUrls: ['./engine-hotel-destination.component.scss']
})

export class EngineHotelDestinationComponent implements OnInit {

  @Output() onNewSelectedDestination = new EventEmitter();

  availableCities: any[];
  availableAccommodations: any[];
  availableZones: any[];
  searchInput: string;
  searchInput$: BehaviorSubject<any> = new BehaviorSubject('');
  
  private unsubscribe$ = new Subject();
  
  constructor(private _hotelService: HotelService) { }

  ngOnInit(): void {
    this.searchInput = '';
    this.searchInput$.pipe( takeUntil(this.unsubscribe$), debounceTime(500) ).subscribe( (newInput: string) => {
      const formattedNewInput = newInput.trim().toLowerCase();
      console.log(formattedNewInput);
      if( formattedNewInput.length < 3 ) return;
      this.newHotelPrefetch(formattedNewInput);
    });
  }
  
  public onSearchInputChange() {
    this.searchInput$.next(this.searchInput);
  }
  
  private newHotelPrefetch(searchTerm: string) {
    this._hotelService.prefetchHotels(searchTerm)
    .then( prefetchResult => {
      if (!prefetchResult?.data?.prefetch?.length) throw new Error("La Prefetch Hotel non ha restituito risultati");
      this.formatAndFilterPrefetchResult(prefetchResult?.data?.prefetch, searchTerm);
    });
  }

  private formatAndFilterPrefetchResult(results : any, searchTerm: string) {

    this.availableCities = results.filter( (item: any) => item.type === 'city' );
    this.availableAccommodations = results.filter( (item: any) => item.type === 'hotel' );
    this.availableZones = results.filter( (item: any) => item.type === 'zone' );

    [this.availableCities, this.availableAccommodations, this.availableZones]
      .forEach( (destinationsArray: any[]) => {
        destinationsArray
        .sort((a: any, b: any) => a.description?.toLowerCase().startsWith(searchTerm.toLowerCase()) ? -1 : 1 )
        .sort((a: any, b: any) => a.description?.toLowerCase() === searchTerm.toLowerCase() ? -1 : 1 )
      });

    this.availableCities = this.availableCities.slice(0,8);
    this.availableAccommodations = this.availableAccommodations.slice(0,15);
    this.availableZones = this.availableZones.slice(0,8);      

  }

  //  Seleziona destination passata come argomento
  public selectDestination(destination: any): void {
    this.onNewSelectedDestination.emit(destination);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
