import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'engine-cruises-departure-date',
  templateUrl: './engine-cruises-departure-date.component.html',
  styleUrls: ['./engine-cruises-departure-date.component.scss']
})
export class EngineCruisesDepartureDateComponent implements OnInit {

  @Input() dates: Date[];
  @Output() onNewSelectedDates = new EventEmitter();

  public availableYears: any[];
  public formattedDates: any[];

  constructor(
    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
    this.getFormattedDate();
  }

  getFormattedDate() {

    this.formattedDates = [];
    this.availableYears = [];

    // Ciclo per ogni data e SE l'anno non è già incluso lo pusho nell'array availableYears
    this.dates.forEach(date  => {
      if (!this.availableYears.includes(date.getFullYear())) this.availableYears.push(date.getFullYear());
    });
    this.availableYears.sort();

    // Per ogni anno disponibile ciclo un nuovo anno
    this.availableYears.forEach(loopedYear => {

      // Creo un oggetto contenente l'anno in questione e un array vuoto da riempire con date matchate
      const yearObject = {
        year: loopedYear,
        matchingDates: [] as any[],
      }

      this.dates.forEach(date => {
        // Creo una costante e all'interno inserisco l'anno attuale
        const currentDateYear = date.getFullYear();

        // Controllo che l'anno attuale è uguale all'anno ciclato e che le date matchate non si ripetino
        if (currentDateYear === loopedYear && !yearObject.matchingDates.some(currentDate => {
            return date.getMonth() === currentDate.getMonth()})) yearObject.matchingDates.push(date);
      })

      // Riordino cronologicamente i mesi
      yearObject.matchingDates.sort((a, b) => {
        const aDate = a.toISOString();
        const bDate = b.toISOString();
        return (aDate < bDate) ? -1 : ((aDate > bDate) ? 1 : 0);
      });

      this.formattedDates.push(yearObject);
    });
  }

  onClickOnDate(date:Date): void {
    this.onNewSelectedDates.emit([date]);
  }

}
