import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { ModalGalleryComponent } from '../modal-gallery/modal-gallery.component';

@Component({
  selector: 'detail-gallery',
  templateUrl: './detail-gallery.component.html',
  styleUrls: ['./detail-gallery.component.scss']
})

export class DetailGalleryComponent implements OnInit {
  
  @Input() imagesUrls: string[];
  @Input() videosUrls: string[] = [];
  
  constructor(private _ngbModal: NgbModal) { }

  ngOnInit(): void {}

  // Apre modale galleria
  openGalleryModal() {
    const galleryModalRef = this._ngbModal.open(ModalGalleryComponent, {
      ariaLabelledBy: 'modal-engine',
      size: 'md',
      centered: true,
      windowClass: 'c-modal--full-screen',
      modalDialogClass: 'modal-gallery__dialog'
    });
    galleryModalRef.componentInstance.images = cloneDeep(this.imagesUrls);

  }

}
