import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, AfterViewInit, ElementRef, TemplateRef } from '@angular/core';
import moment from 'moment';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import delayAction from 'src/app/utils/delayAction';
import SwiperCore, { SwiperOptions, Navigation, Pagination, Scrollbar, A11y, Swiper } from 'swiper';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MobileService } from 'src/app/services/mobile.service';

SwiperCore.use([Navigation, Pagination, A11y]);

@Component({
  selector: 'detail-dates',
  templateUrl: './detail-dates.component.html',
  styleUrls: ['./detail-dates.component.scss']
})
export class DetailDatesComponent implements OnInit, OnChanges, AfterViewInit {

  public isMobile: boolean;

  private unsubscribe$ = new Subject;

  public detailDatesSliderConfig = {
    centerInsufficientSlides: true,
    slidesPerView: 'auto',
    touchEventsTarget: 'wrapper',
    navigation: {
      nextEl: '.swiper-button-calendar-next',
      prevEl: '.swiper-button-calendar-prev',
    },
    spaceBetween: 16,
    observeSlideChildren: true,
    observeParents: true,
    observer: true,
    resistanceRatio: 0,
    threshold: 25,
  }
  constructor(
    private _mobileService: MobileService
  ) { }
  
  @Input() allAvailableDates: Date[];
  @Input() currentSearchDate: Date;

  @Output() onNewDateSelected = new EventEmitter();    
  @ViewChild('newswiper', { static: false }) datesSwiper: SwiperComponent;
  
  ngOnInit(): void {
    //  Gestione Vista Mobile
    this._mobileService.mobileView$.pipe(takeUntil(this.unsubscribe$)).subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }
  
  ngAfterViewInit(): void {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.currentSearchDate || changes.allAvailableDates) && this.currentSearchDate && this.allAvailableDates) {
      delayAction( () => this.goToCurrentSearchDate() );
    }
  }

  public onClickOnDate(date: Date) {
    this.onNewDateSelected.emit(date);
  }

  public goToCurrentSearchDate() {
    const dateIndex = this.allAvailableDates.findIndex( (existingDate: Date) => {
      return moment(this.currentSearchDate).isSame(moment(existingDate), 'day');      
    })
    
    this.datesSwiper.directiveRef.setIndex(dateIndex, 500, true);
  }
  
  
}
