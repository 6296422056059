import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'consenso-advertising',
  templateUrl: './consenso-advertising.component.html',
  styleUrls: ['./consenso-advertising.component.scss']
})
export class ConsensoAdvertisingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
