import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'skeleton-detail-dates',
  templateUrl: './skeleton-detail-dates.component.html',
  styleUrls: ['./skeleton-detail-dates.component.scss']
})
export class SkeletonDetailDatesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
