import {Component, Input, OnInit} from '@angular/core';
import {CheckoutSummaryCardData} from "@interfaces/generic/checkout-summary-card-data";

@Component({
  selector: 'checkout-summary-card',
  templateUrl: './checkout-summary-card.component.html',
  styleUrls: ['./checkout-summary-card.component.scss']
})
export class CheckoutSummaryCardComponent implements OnInit {

  @Input() summaryCardData: CheckoutSummaryCardData;

  constructor() { }

  ngOnInit(): void {}

}
