import { HolidayTypeList } from "../enum/holiday/HolidayType.enum";

export const HolidayType = {
  All: [
    HolidayTypeList.SOLO_SOGGIORNO,
    HolidayTypeList.VOLO_SOGGIORNO,
    HolidayTypeList.BUS_SOGGIORNO,
    HolidayTypeList.NAVE_SOGGIORNO,
    HolidayTypeList.VOLO_TOUR,
    HolidayTypeList.VOLO_TOUR_SOGGIORNO,
    HolidayTypeList.TRENO_SOGGIORNO,
    HolidayTypeList.SOLO_TOUR,
    HolidayTypeList.VOLO_TOUR_CROCIERA,
    HolidayTypeList.BUS_TOUR_SOGGIORNO,
    HolidayTypeList.VOLO_CROCIERA_SOGGIORNO,
    HolidayTypeList.BUS_TOUR,
  ],
  HolidayOnly: [
    HolidayTypeList.SOLO_SOGGIORNO,
    HolidayTypeList.BUS_SOGGIORNO,
    HolidayTypeList.TRENO_SOGGIORNO,
    HolidayTypeList.SOLO_TOUR,
    HolidayTypeList.BUS_TOUR_SOGGIORNO,
    HolidayTypeList.BUS_TOUR,
  ],
  HolidayBus: [HolidayTypeList.BUS_SOGGIORNO, HolidayTypeList.BUS_TOUR_SOGGIORNO, HolidayTypeList.BUS_TOUR],
  HolidayFlight: [HolidayTypeList.VOLO_SOGGIORNO, HolidayTypeList.VOLO_TOUR, HolidayTypeList.VOLO_TOUR_SOGGIORNO],
  HolidayShip: [HolidayTypeList.NAVE_SOGGIORNO],
};
