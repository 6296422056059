import { Component, Input, OnInit } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'hotel-search-filters',
  templateUrl: './hotel-search-filters.component.html',
  styleUrls: ['./hotel-search-filters.component.scss']
})
export class HotelSearchFiltersComponent implements OnInit {

  @Input() filterObject: any = null;
  @Input() searchCount: { all: number, filtered: number } = null;
  @Input() isInsideModal: boolean = false;

  constructor(private _filterService: FilterService) { }

  ngOnInit(): void {
  }  

  public updateCategoryFilter(newHotelCategoryFilterSelection: any) {  
    this.filterObject.category = newHotelCategoryFilterSelection;
    this.applyFilters();
  }

  public updateFacilitiesFilter(newHotelFacilitiesFilterSelection: any) {  
    this.filterObject.facilities = newHotelFacilitiesFilterSelection;
    this.applyFilters();
  }

  public updatePriceFilter(newHotelPriceFilterSelection: any) {
    this.filterObject.prices = newHotelPriceFilterSelection;
    this.applyFilters();
  }

  public updateDistanceFilter(newHotelDistanceFilterSelection: any) {
    this.filterObject.distance = newHotelDistanceFilterSelection;
    this.applyFilters();
  }

  public updateOrderFilter(newHotelOrderByFilterSelection: any) {
    this.filterObject.orderBy = newHotelOrderByFilterSelection;
    this.applyFilters();
  }

  public resetAllFilters() {
    this._filterService.resetHotelFilters();
  }

  private applyFilters() {
    this._filterService.updateHotelFilters(this.filterObject);
  }

}
