import {Pipe, PipeTransform} from '@angular/core';
import {cloneDeep} from 'lodash';
import {HolidaySolution} from 'src/app/interfaces/holiday/holiday.interface';
import {Holiday} from 'src/app/models/Holiday.class';

@Pipe({
  name: 'searchFiltersHoliday'
})
export class SearchFiltersHolidayPipe implements PipeTransform {

  transform(originalHolidays: Holiday[], filterObject: any): Holiday[] {

    const filteredHolidays = originalHolidays.filter((holiday: Holiday) => {


      // Accommodation Name Filter
      if (filterObject?.accommodationName) {
        const selectedAccommodationName = filterObject?.accommodationName.availableValues
          ?.find((accommodationValues: { isSelected: boolean, value: string }) => {
            return accommodationValues.isSelected;
          })?.value;
        if (selectedAccommodationName && (!holiday.accommodation?.name || holiday.accommodation.name !== selectedAccommodationName)) {
          return false;
        }
      }

      //  Treatment Filter
      if (filterObject?.treatment) {
        const selectedTreatmentValues = filterObject?.treatment.availableValues
          ?.filter((valObj: any) => valObj.isSelected)
          ?.map((valObj: any) => valObj.value)

        if (selectedTreatmentValues?.length) {
          //  Filtra solo le soluzioni il cui trattamento corrisponde ad uno di quelli selezionati dall'utente
          holiday.solutions = holiday.solutions.filter((solution: HolidaySolution) => solution.basis?.name && selectedTreatmentValues.includes(solution.basis.name))
          //  Se a seguito del filtraggio non ci sono più soluzioni rimuove direttamente la holiday
          if (!holiday.solutions?.length) return false;
        }
      }

      //  Duration Filter
      if (filterObject?.duration) {
        const selectedDurationValues = filterObject?.duration.availableValues
          ?.filter((valObj: any) => valObj.isSelected)
          ?.map((valObj: any) => valObj.value)

        if (selectedDurationValues?.length) {
          //  Filtra solo le soluzioni la cui durata corrisponde ad una di quelle selezionate dall'utente
          holiday.solutions = holiday.solutions.filter((solution: HolidaySolution) => solution.nights && selectedDurationValues.includes(solution.nights))
          //  Se a seguito del filtraggio non ci sono più soluzioni rimuove direttamente la holiday
          if (!holiday.solutions?.length) return false;
        }
      }

      //  TourOperator Filter
      if (filterObject?.tourOperator) {
        const selectedTourOperatorValues = filterObject?.tourOperator?.availableValues
          ?.filter((valObj: any) => valObj.isSelected)
          ?.map((valObj: any) => valObj.value)

        if (selectedTourOperatorValues.length) {
          if (!selectedTourOperatorValues.includes(holiday.tourOperator?.name)) {
            return false;
          }
        }
      }

      //  Category Filter
      if (filterObject?.category) {
        const selectedCategoryValues = filterObject?.category?.availableValues
          ?.filter((valObj: any) => valObj.isSelected)
          ?.map((valObj: any) => valObj.value)

        if (selectedCategoryValues.length) {
          const holidayAccommodationStars = Number(holiday.accommodation?.category?.name?.slice(0, 1));
          if (!holidayAccommodationStars || !selectedCategoryValues.includes(holidayAccommodationStars)) {
            return false;
          }
        }
      }

      //  Price Filter
      if (filterObject?.prices) {
        const {minPriceSelected, maxPriceSelected, minPriceAbsolute, maxPriceAbsolute} = filterObject?.prices;
        if (minPriceSelected !== minPriceAbsolute || maxPriceSelected !== maxPriceAbsolute) {  //  Se il filtro è stato 'toccato'
          const holidayBestPrice = holiday.solutions[0].totalPrice || null;
          if (!holidayBestPrice || holidayBestPrice < minPriceSelected || holidayBestPrice > maxPriceSelected) {
            return false;
          }
        }
      }

      return true;

    })

    //  OrderBy
    if (filterObject?.orderBy) {
      const selectedOrder = filterObject?.orderBy?.find((option: any) => option.isSelected).value as 'priceAsc' | 'priceDesc' | 'featured' || 'featured';

      filteredHolidays.sort((holidayA: Holiday, holidayB: Holiday) => {
        if (selectedOrder === 'priceAsc' || selectedOrder === 'featured') return holidayA.solutions[0]?.mkPriceCP - holidayB.solutions[0]?.mkPriceCP;
        if (selectedOrder === 'priceDesc') return holidayB.solutions[0]?.mkPriceCP - holidayA.solutions[0]?.mkPriceCP;
        return 0;
      });

      if (selectedOrder === 'featured') {
        filteredHolidays.sort((holidayA: Holiday, holidayB: Holiday) => holidayA.isCustom ? -1 : 1);
      }
    }

    return filteredHolidays;

  }

}
