import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public loadingStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadingMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private loadingStack: any[] = [];

  private readonly defaultLoadingMessage: string = 'Caricamento in Corso...';

  constructor() {}

  private updateLoader(): void {
    this.loadingStatus$.next(this.loadingStack.length > 0);    
    const currentLoadingMessage = this.loadingStack.length ? this.loadingStack[this.loadingStack.length - 1]?.message : null;
    //  Imposta come messaggio di loadeing quello passato come proprietà del loadingItem o, in sua assenza, quello di default
    this.loadingMessage$.next(currentLoadingMessage ||this.defaultLoadingMessage);
  }

  addLoad(loadingItem: any): void {
    this.loadingStack.push(loadingItem);
    this.updateLoader();
  }

  removeLoad(loadingItem: any): void {
    this.loadingStack = this.loadingStack.filter((e) => e !== loadingItem);
    this.updateLoader();
  }
}
