import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tourOperatorLogo'
})
export class DynamicTourOperatorPipe implements PipeTransform {

  transform(tourOperatorId: any): unknown {
    return 'https://cdn.otosrl.com/images/touroperator/mini/' + tourOperatorId + '-' + tourOperatorId + '.png';
  }
  
}