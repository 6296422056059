import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalsTypologies } from 'src/app/enum/generic/ModalsTypologies';
import { IHolidayReservationCancelParams } from 'src/app/interfaces/holiday/reservation-requests.interface';
import { HolidayService } from 'src/app/services/holiday.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'cancel-page',
  templateUrl: './cancel-page.component.html',
  styleUrls: ['./cancel-page.component.scss']
})
export class CancelPageComponent implements OnInit {

  unsubscribe$ = new Subject();

  constructor(
    private _route: ActivatedRoute,
    private _holidayService: HolidayService,
    private _modalService: ModalService
  ) { }

  ngOnInit(): void {
  }

  public onClickOnContactUs() {
    const helpModalOptions = {
      modalConfig: {
        ariaLabelledBy: 'modal-help',
        size: 'lg',
        centered: true,
      },
      modalSrc: {
        modalTypology: ModalsTypologies.HELP,
        additionalOptions: {
          modalTitle: 'Richiesta di assistenza',
          modalDescription: 'Compila il form qui sotto e descrivi il problema riscontrato inserendo più dettagli possibile. Un nostro incaricato ti risponderà il prima possibile per fornirti assistenza!',
          showDescription: false,
          inputDescription: true,
        }
      },
    }

    this._modalService.openModal(helpModalOptions);

  }

}
