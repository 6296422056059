import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { HolidaySolution, TourItineraryItemExperience } from 'src/app/interfaces/holiday/holiday.interface';
import PassengerData from 'src/app/models/PassengerData.class';
import { ModalGalleryComponent } from 'src/app/modules/shared/components/modal-gallery/modal-gallery.component';
import { HolidayService } from 'src/app/services/holiday.service';
import delayAction from 'src/app/utils/delayAction';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'holiday-checkout-experiences-details',
  templateUrl: './holiday-checkout-experiences-details.component.html',
  styleUrls: ['./holiday-checkout-experiences-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HolidayCheckoutExperiencesDetailsComponent implements OnInit {

  @Input() experience: TourItineraryItemExperience;
  @Input() passengers: PassengerData[];
  @Input() selectedSolution: HolidaySolution;

  @Output() confirmExperienceSelection = new EventEmitter();
  @Output() closeModal = new EventEmitter();  

  public isLoadingAvailability: boolean = false;
  public noAvailability: boolean = false;

  public showMissingRequiredFieldsError: boolean = false;

  public experiencePassengersAges: {age: number, selected: boolean}[];
  public selectedPaxNumber: number;

  public experienceDepartures: any[] = [];
  public selectedDeparture: any;

  constructor(
    private _ngbModal: NgbModal,
    private _holidayService: HolidayService) { }

  ngOnInit(): void {

    this.experiencePassengersAges = this.passengers.map( (pax: PassengerData) => ({age: pax.age, selected: false}));

    if(this.experience) {
      this.checkExperienceAvailability(this.experience);
    } else console.log("no experience no party");

  }

  private checkExperienceAvailability(experience: any) {

    const params = {
      apiKey: environment.apiKey,
      idExperience: experience.id,
      departureDate: moment(this.selectedSolution?.departureDate).format('YYYY-MM-DD'),
      checkout: moment(this.selectedSolution?.departureDate).add(this.selectedSolution?.nights, 'days').format('YYYY-MM-DD'),
      ages: this.passengers.map((passenger: PassengerData) => passenger.age)
    }
    
    this.isLoadingAvailability = true;

    this._holidayService.getExperienceAvailability(params)
      .then( result => {
        if (!result?.experiences?.length) throw new Error("NO AVAILABILITY FOR SELECTED EXPERIENCE");
        this.experienceDepartures = result.experiences.map((experience: any) => experience.experienceDeparture );
      })
      .catch( (err) => this.noAvailability = true )
      .finally( () => this.isLoadingAvailability = false );

  }

  public openExperienceGallery() {
    const galleryModalRef = this._ngbModal.open(ModalGalleryComponent, {
      ariaLabelledBy: 'modal-experience-gallery',
      size: 'md',
      centered: true,
      windowClass: 'c-modal--full-screen',
      modalDialogClass: 'modal-gallery__dialog'
    });
    galleryModalRef.componentInstance.images = cloneDeep(this.experience?.medias.map( (mediaObj: any) => mediaObj.url ));
    
  }

  public confirmExperienceDeparture() {

    if (!this.selectedDeparture || !this.selectedPaxNumber) {
      this.showMissingRequiredFieldsError = true;
      return;
    }

    const idExperience = this.experience.id;
    const idDeparture = this.selectedDeparture.id;
    const departureDate = this.selectedDeparture.departureDate;
    const passengers = this.getSelectedPassengers();
    this.confirmExperienceSelection.emit({idExperience, idDeparture, departureDate, passengers});
  }

  private getSelectedPassengers(): PassengerData[] {
    return this.experiencePassengersAges
    .filter( (paxAgeObj: {age: number, selected: boolean}) => paxAgeObj.selected )
    .map( (paxAgeObj: {age: number, selected: boolean}) => {
      const matchingPassenger = this.passengers.find( (pax: PassengerData) => pax.age === paxAgeObj.age );
      return cloneDeep(matchingPassenger);
    } );

  }

  public onPassengerSelectedDepartureChange() {
    this.showMissingRequiredFieldsError = false;
  }

  public onClickOnCloseModal() {
    this.closeModal.emit();
  }

  public onPassengerToggle(){
    this.showMissingRequiredFieldsError = false;
    delayAction( () => {
      this.selectedPaxNumber = this.experiencePassengersAges.filter( (pa: any) => pa.selected)?.length;
    }, 70);
  }

}
