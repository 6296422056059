import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'map-wrapper',
  templateUrl: './map-wrapper.component.html',
  styleUrls: ['./map-wrapper.component.scss']
})
export class MapWrapperComponent implements OnInit {

  @Input() coordinates: { progressive: string, latitude: number, longitude: number }[];

  public zoom: number;

  public mapOptions: google.maps.MapOptions;
  public mapBounds: google.maps.LatLngBounds;
  public markerOptions: google.maps.MarkerOptions;

  @ViewChild(GoogleMap, {static: false}) map: GoogleMap;

  
  constructor() { 
    this.mapOptions = {
      center: null,
      controlSize: 24,
      zoom: 8,
      disableDefaultUI: false,
      backgroundColor: 'ghostwhite',
      styles: []
    };

    this.markerOptions = {
      icon: this.createCustomMarker('#FAB600')
    }
      
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.mapBounds = new google.maps.LatLngBounds();
    
    this.coordinates?.forEach(x => {
      let pos = new google.maps.LatLng(x.latitude, x.longitude);
      this.mapBounds.extend(pos);
    });
    
    if(this.coordinates.length > 1) {
      this.map?.fitBounds(this.mapBounds);
    } else  {
      this.mapOptions.center = new google.maps.LatLng(this.coordinates[0].latitude, this.coordinates[0].longitude);
      this.map.center = this.mapOptions.center;
    }
    
  }

  private createCustomMarker(fillColor: string) {
    return {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
        fillColor: fillColor,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 2,
        scale: 1
    };
  }

}
