import { Pipe, PipeTransform } from '@angular/core';
import { MappingService } from '../services/mapping.service';

@Pipe({
  name: 'destinationById'
})
export class DestinationByIdPipe implements PipeTransform {

  constructor(private _mappingService: MappingService) {}

  transform(destinationId: number | string, fieldToReturn: 'Id' | 'Name' | 'ParentID' = 'Name'): string | number {    
    const destinationObj = this._mappingService.getDestinationById(Number(destinationId));
    return destinationObj && destinationObj[fieldToReturn] ? destinationObj[fieldToReturn] : null;
  }

}
