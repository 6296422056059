import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalGenericComponent } from '../modules/shared/components/modal-generic/modal-generic.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  
  constructor( private _ngbModal: NgbModal ) { }

  public openModal(modalOptions: any) {
    const { modalConfig, modalSrc } = modalOptions;    
    const modalRef =  this._ngbModal.open(ModalGenericComponent, modalConfig);
    modalRef.componentInstance.modalSrc = modalSrc;    
    return modalRef;
  }
  
  //TODO da deprecare: ancdrebbe richiamato all'onInit delle modali...
  public onModalOpen() {
    //  Nasconde momentaneamente l'overflow del body per evitare la doppia scrollbar uslle modali
    document.body.style.overflow = 'hidden';
  }

  public onModalClosed() {
    //  Ripristina overflow del body alla chiusura della modale
    document.body.style.overflow = 'visible';    
  }

}
