import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {map, tap} from 'rxjs/operators';
import { HolidaySearchParams, HolidaySearchResponse } from '../interfaces/holiday/holidaySearch.interface';
import { environment } from 'src/environments/environment';
import fixArray from '../utils/fixArray';
import { Holiday } from '../models/Holiday.class';
import {HolidayType} from '../values/HolidayTypeList.value';
import * as moment from 'moment';
import { HolidayDetailParams } from '../interfaces/holiday/holidayDetail.interface';
import { HolidayDetailResponse } from '../interfaces/holiday/holidaySearchDetail.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import PassengerData from '../models/PassengerData.class';
import { AvailabilityParams, AvailabilitySolutionRoom } from '../interfaces/holiday/availability';
import { HolidayAdditionalServiceCharge, HolidayAdditionalServiceParams, HolidayAdditionalServiceResponse } from '../interfaces/holiday/holidayAdditionalService.interface';
import { IZeusResponse } from '../interfaces/generic/zeus-response.interface';
import { HolidayQuotation, HolidayQuotationParams, HolidayQuotationResponse, HolidayQuotationSlimResponse } from '../interfaces/holiday/holidayQuotation.interface';
import { IHolidayReservationCancelParams, IHolidayReservationConfirmParams, IHolidayReservationCreateParams } from '../interfaces/holiday/reservation-requests.interface';
import { PrivacyRequest } from '../interfaces/generic/privacy-request.interface';
import { Occupancy } from '../interfaces/generic/occupancy';
import { HolidaySolution, TourItineraryItemExperience } from '../interfaces/holiday/holiday.interface';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class HolidayService {

  private readonly apiUrl = environment.apiurl;
  private readonly api2Url = environment.api2url;
  private readonly apiKey = environment.apiKey;

  private httpCache: Map<string, any> = new Map<string, any>();

  public bookingObject: any = {
    passengers: null as PassengerData[],
    room: null as AvailabilitySolutionRoom,
    availableExperiences: [] as TourItineraryItemExperience[],
    availableAdditionalServices: [] as HolidayAdditionalServiceCharge[],
    availableInsurances: [] as HolidayAdditionalServiceCharge[],
    additionalServices: [],
    selectedExperiences: [] as {idExperience: number, idDeparture: number, departureDate: Date, passengers: PassengerData[]}[],
    insurances: [],
    holidayQuotation: null as HolidayQuotation,
    customer: null as PassengerData,
    privacyContent: [] as PrivacyRequest[],
  };

  public bookingObject$: BehaviorSubject<any> = new BehaviorSubject<any>(this.bookingObject);
  public changeStep$ = new Subject<number>();

  constructor(private _http: HttpClient,
              private _commonService: CommonService) { }

  public updateBookingObject(bookingObject: any) {
    this.bookingObject = bookingObject;
    this.bookingObject$.next(this.bookingObject);
  }

  public searchHolidaySlim(params: HolidaySearchParams): Promise<Holiday[]> {
    return this._http
      .post<HolidaySearchResponse>(`${this.apiUrl}/index.php/holiday/${this.apiKey}/search/slim`, params)
      .pipe(
        tap((response) => {
          if (response?.error) {
            throw new Error(response?.message ? response?.message : 'Cannot Get Holidays');
          }
        }),
        map((response) => fixArray(response?.Result?.Holiday_Collection?.HolidaySlim)),
        map((response) => response.map((holiday) => new Holiday().fromHolidaySlim(holiday))),
      )
      .toPromise();
  }

  public searchHolidayLiveSlim(params: HolidaySearchParams): Promise<Holiday[]> {
    return this._http
      .post<HolidaySearchResponse>(`${this.apiUrl}/index.php/holiday/${this.apiKey}/searchLive/slim`, params)
      .pipe(
        tap((response) => {
          if (response?.error) {
            throw new Error(response?.message ? response?.message : 'Cannot Get Live Holidays');
          }
        }),
        map((response) => fixArray(response?.Result?.Holiday_Collection?.HolidaySlim)),
        map((response) => response.map((holiday) => new Holiday().fromHolidaySlim(holiday))),
      )
      .toPromise();
  }

  //TODO gestire cache
  public getAdditionalServices(params: HolidayAdditionalServiceParams): Promise<HolidayAdditionalServiceCharge[]> {
    return this._http
      .post<IZeusResponse<HolidayAdditionalServiceResponse>>(`${environment.holiday}/${this.apiKey}/additionalService`, params)
      .pipe(
        tap((response) => {
          if (response.error) {
            throw new Error(response.message);
          }
        }),
        map((response) => response.result),
        map((result) => {
          return result.service?.[0]?.extraChargesCollection ? result.service?.[0]?.extraChargesCollection : [];
        }),
      )
      .toPromise();
  }

  //TODO gestire cache
  public getInsuranceServices(params: HolidayAdditionalServiceParams): Promise<HolidayAdditionalServiceCharge[]> {
    return this._http
      .post<IZeusResponse<HolidayAdditionalServiceResponse>>(`${environment.holiday}/${this.apiKey}/insuranceService`, params)
      .pipe(
        tap((response) => {
          if (response.error) {
            throw new Error(response.message);
          }
        }),
        map((response) => response.result),
        map((result) => {
          return result.service?.[0]?.extraChargesCollection ? result.service?.[0]?.extraChargesCollection : [];
        }),
      )
      .toPromise();
  }

  //  Chiamata availability (liveAlpi)
  public availabilityRequest(params: AvailabilityParams): Promise<any> {
    const url = `${this.apiUrl}/index.php/holiday/${this.apiKey}/user/book/livealpi`;
    const stringParams = url + JSON.stringify(params);
    let cachedApi = this.httpCache.get(stringParams);

    if (!cachedApi) {
      return this._http
      .post<any>(url, params)
      .pipe(
        tap((response) => {
          if (response?.Error || !response?.Result?.GetAdditionalRoomsResponse) {
            throw new Error('Cannot Get Holiday Availability');
          }
        }),
        map((response) => response?.Result?.GetAdditionalRoomsResponse),
        tap((response) => {
          cachedApi = response;
          this.httpCache.set(stringParams, cachedApi);
        })
        )
        .toPromise()
    } else {
      console.log("cached liveAlpi\n", cachedApi);
      return Promise.resolve(cachedApi);
    }
  }

  //  Chiamata di dettaglio holiday
  public getHolidayDetail(params: HolidayDetailParams): Promise<Holiday[]> {
    const url = `${this.apiUrl}/index.php/holiday/${this.apiKey}/search/from/${params.dateFrom}/to/${params.dateTo}/accommodation/${params.accommodationId}/tops/${params.topId}/sourceId/${params.source}/plugin/${params.pluginSource}?ages=${params.ages}`;
    const stringParams = url + JSON.stringify(params);
    let cachedApi = this.httpCache.get(stringParams);
    if (!cachedApi) {

    return this._http
      .get<HolidayDetailResponse>(url)
      .pipe(
        tap((response) => {
          if (response?.Error) {
            throw new Error(response?.Message ? response?.Message : 'Cannot Get Holiday Detail');
          }
          console.debug('######## HOLIDAY DETAIL ########\n', response, '\n################################');
        }),
        map((response) =>
          fixArray(response.Result.Holiday_Collection.Holiday).map((holidayRaw) => new Holiday().fromHolidayDetail(holidayRaw)),
        ),
        tap((response) => {
          cachedApi = response;
          this.httpCache.set(stringParams, cachedApi);
        })
      )
      .toPromise();
    } else {
      console.log("cached holidayDetail\n", cachedApi);
      return Promise.resolve(cachedApi);
    }

  }

  //  Chiamata di quotationSlim
  public quotationSlim(params: HolidayQuotationParams): Promise<HolidayQuotation> {
    const url = `${this.api2Url}/oto-api/holiday/nauth/${this.apiKey}/quotationSlim`;
    const stringParams = url + JSON.stringify(params);
    let cachedApi = this.httpCache.get(stringParams);
    if (!cachedApi) {
    return this._http
      .post<IZeusResponse<HolidayQuotationSlimResponse>>(url, params)
      .pipe(
        tap((response) => {
          if (response.error) {
            throw new Error(response.message);
          }
        }),
        map((response) => response.result?.quotationSlimResponse),
        tap((response) => {
          cachedApi = response;
          this.httpCache.set(stringParams, cachedApi);
        })
      )
      .toPromise();
    } else {
      console.warn("cached quotationSlim\n", cachedApi);
      return Promise.resolve(cachedApi);
    }
  }

  //  Chiamata di quotation
  public quotation(params: HolidayQuotationParams): Promise<HolidayQuotation> {
    return this._http
      .post<IZeusResponse<HolidayQuotationResponse>>(`${this.api2Url}/oto-api/holiday/nauth/${this.apiKey}/quotation`, params)
      .pipe(
        tap((response) => {
          if (response.error) {
            throw new Error(response.message);
          }
        }),
        map((response) => response.result?.quotationResponseV2),
      )
      .toPromise();
  }


  public reservation(params: IHolidayReservationCreateParams): Promise<any> {
    return this._http
      .post<any>(`${environment.holiday}/${this.apiKey}/reservation`, params)
      .pipe(
        tap((response) => {
          if (response.error) {
            throw new Error(response.message);
          }
        }),
        map((response) => response.result?.reservationResponseV2)
      )
      .toPromise();
  }


public confirmReservation(params: IHolidayReservationConfirmParams): Promise<any> {
  return this._http
    .post<any>(`${this.apiUrl}/index.php/holiday/${this.apiKey}/user/book/confirm`, params)
    .pipe(
      tap((response) => {
        if (response.error) {
          throw new Error(response.message);
        }
      }),
      map((response) => response.Result?.ConfirmReservationResponse),
    )
    .toPromise();
}

public cancelReservation(params: IHolidayReservationCancelParams): Promise<any> {
  return this._http
    .post<any>(`${environment.holiday}/${this.apiKey}/cancelReservation`, params)
    .pipe(
      tap((response) => {
        if (response.error) {
          throw new Error(response.message);
        }
      }),
      map((response) => response.result?.cancelReservationResponse),
    )
    .toPromise();
}

  //TODO gestire cache
  public getExperienceAvailability(params: any): Promise<any> {
    return this._http
      .post<IZeusResponse<any>>(`${this.api2Url}/oto-api/search/nauth/experiences/availability`, params)
      .pipe(
        tap((response) => {
          if (response.error) {
            throw new Error(response.message);
          }
        }),
        map((response) => response.result),
      )
      .toPromise();
  }


  //  Passato l'id di un departurePlace restituisce la lista di holidayTipesList compatibili
  public getHolidayTypeListByDeparturePlace(departurePlace: number): number[] {
    if (departurePlace !== null && !departurePlace) {
      return [];
    }
    if (departurePlace === null) {
      return HolidayType.HolidayOnly;
    }
    if (departurePlace < 1000000) {
      return HolidayType.HolidayBus;
    }
    if (departurePlace < 2000000) {
      return HolidayType.HolidayFlight;
    }
    return HolidayType.HolidayShip;
  }


  // --- Payloads and utilities


  //  Fornisce payLoad per le chiamate di Dettaglio holiday
  public getHolidayDetailParams(occupancy: Occupancy, source: string, topId: number, pluginSource: number, accommodationId: number, departureDate: Date): HolidayDetailParams {
    const ages = this._commonService.getAgesArrayFromOccupancy(occupancy);
    const { dateFrom, dateTo } = this.getTimeRangeFromDate(departureDate, 0);    
    return { source, topId, pluginSource, accommodationId, ages, dateFrom, dateTo };
  }

  //  Fornisce payLoad per le chiamate di Availability (liveAlpi)
  public getAvailabilityParams(occupancy: Occupancy, solution: HolidaySolution, topId: number, pluginSource: number) : AvailabilityParams {
    const ages = this._commonService.getAgesArrayFromOccupancy(occupancy);
    const {departureDate, source: sourceId, id: idSolution } = solution;
    //Prima di passare la data come argomento la porto alla timezone italiana
    let localeString = departureDate.toLocaleString('it-IT', {timeZone: 'Europe/Rome'})
    let arr = localeString.split('/');
    let depDate = new Date(Number(arr[2].split(',')[0]), (Number(arr[1])-1), Number(arr[0]));
    /* let month = (depDate.getMonth() + 1).toString();
    month = month.length === 1 ? ('0' + month) : month;
    let date = depDate.getDate().toString();
    date = date.length === 1 ? ('0' + date) : date; */

    //const depDateString = depDate.getFullYear() + "-" + month + '-' + date;
    return {
      request: {
        ages, departureDate: moment(depDate).format('YYYY-MM-DD'),
        sourceId, idSolution, onlyNetQuotes: false, stationId: null,  //TODO flusso bus...
        departurePlace: solution.departurePlace?.id || null, topId, pluginSource
      }
    }
  }

  //  Passati come argomenti una data 'date' ed un intervallo di tempo 'daysRange' in giorni restituisce un intevallo di date che vanno da -daysRange a +daysRange
  public getTimeRangeFromDate(date: Date, daysRange: number = 5): { dateFrom: string, dateTo: string} {
    const today = moment();
    //  Se sottraendo daysRange alla data odierna si ottiene una data passata, la dateFrom sarà uguale alla data odierna
    const dateFrom = moment(date).subtract(daysRange, 'days').isAfter(today) ? moment(date).subtract(daysRange, 'days') : today;
    const dateTo = moment(dateFrom).add( 2 * daysRange, 'days');
    const offset = dateFrom.toDate().getTimezoneOffset();
    return { dateFrom: (dateFrom.unix()-offset*60).toString(), dateTo: (dateTo.unix()-offset*60).toString() }
  }
}
