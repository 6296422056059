export enum HolidayTypeList {
    SOLO_SOGGIORNO = 1,
    VOLO_SOGGIORNO = 2,
    BUS_SOGGIORNO = 4,
    NAVE_SOGGIORNO = 6,
    SOLO_VOLO = 8,
    VOLO_NAVIGAZIONE = 9,
    VOLO_TOUR = 11,
    VOLO_NAVIGAZIONE_HOTEL = 13,
    VOLO_AUTO = 15,
    VOLO_TOUR_SOGGIORNO = 16,
    CROCIERA = 17,
    VOLO_CROCIERA = 18,
    TRENO_SOGGIORNO = 19,
    VOLO_SAFARI = 20,
    SOLO_TOUR = 21,
    ROULETTE = 22,
    COMBINATO = 23,
    VOLO_TOUR_CROCIERA = 24,
    BUS_TOUR_SOGGIORNO = 25,
    VOLO_TOUR_BUS = 26,
    VOLO_CROCIERA_SOGGIORNO = 27,
    CROCIERA_FLUVIALE = 34,
    BUS_TOUR = 35,
}
  