import { Pipe, PipeTransform } from '@angular/core';
import { HolidaySolution, HolidaySolutionPacket } from '../interfaces/holiday/holiday.interface';

@Pipe({
  name: 'filterPackets'
})
export class FilterPacketsPipe implements PipeTransform {

  //  passate le solutions di una holiday come argomento restituisce un array in cui ogni elemento rappresenta un pacchetto
  //  Ogni elemento/pacchetto dell'array avrà come proprietà il title del pacchetto stesso, la sua immagine di copertura ed un...
  //  ...ulteriore array che contiene tutte le soluzioni che fanno parte del pacchetto stesso.
  //  Le solutions originali passate come argomento saranno in definitiva smistate all'interno dei pachetti disponibili per la holiday

  transform(solutions: HolidaySolution[]): unknown {

      return solutions
      .map( (solution: HolidaySolution) => solution.packet)
      .filter( (packet: HolidaySolutionPacket, index:number, selfArray: HolidaySolutionPacket[] ) => {
          return selfArray.findIndex( (_pkt: HolidaySolutionPacket) => _pkt.id === packet.id ) === index;
        })
      .map( (packet: HolidaySolutionPacket) => {
        return {
          title: packet.title,
          image: packet.coverImageBaseUrl + packet.coverImage,
          solutions: solutions
          .filter( (solution: HolidaySolution) => solution.packet.id === packet.id)
          .sort( (solutionA: HolidaySolution, solutionB: HolidaySolution) => solutionA.mkPriceCP - solutionB.mkPriceCP)
        }
      });

  }

}
