import { Component, Input, OnInit } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'holiday-search-filters',
  templateUrl: './holiday-search-filters.component.html',
  styleUrls: ['./holiday-search-filters.component.scss']
})
export class HolidaySearchFiltersComponent implements OnInit {

  @Input() filterObject: any = null;
  @Input() searchCount: { all: number, filtered: number } = null;
  @Input() isInsideModal: boolean = false;

  constructor(private _filterService: FilterService) { }

  ngOnInit(): void {
  }

  public updateHolidayTopFilter(newHolidayTopFilterSelection: any) {
    this.filterObject.tourOperator = newHolidayTopFilterSelection;
    this.applyFilters();
  }

  public updateHolidayAccommodationNameFilter(selectedAccommodation: { isSelected : boolean, value: string }) {

    this.filterObject?.accommodationName?.availableValues?.forEach( (accommodation: { isSelected : boolean, value: string }) => {
      accommodation.isSelected = selectedAccommodation && accommodation && (accommodation.value === selectedAccommodation.value);
    });
    this.applyFilters();
  }

  public updateCategoryFilter(newHolidayCategoryFilterSelection: any) {
    this.filterObject.category = newHolidayCategoryFilterSelection;
    this.applyFilters();
  }

  public updatePriceFilter(newHolidayPriceFilterSelection: any) {
    this.filterObject.prices = newHolidayPriceFilterSelection;
    this.applyFilters();
  }

  public updateDurationFilter(newHolidayDurationFilterSelection: any) {
    this.filterObject.durations = newHolidayDurationFilterSelection;
    this.applyFilters();
  }

  public updateTreatmentFilter(newHolidayTreatmentFilterSelection: any) {
    this.filterObject.treatment = newHolidayTreatmentFilterSelection;
    this.applyFilters();
  }

  public updateOrderFilter(newHolidayOrderByFilterSelection: any) {
    this.filterObject.orderBy = newHolidayOrderByFilterSelection;
    this.applyFilters();
  }

  public resetAllFilters() {
    this._filterService.resetHolidayFilters();
  }

  private applyFilters() {
    this._filterService.updateHolidayFilters(this.filterObject);
  }

}
