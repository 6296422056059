import { cloneDeep, forEach, orderBy } from 'lodash';
import {
  HolidaySearchSolution,
  HolidaySearchSolutionOperative,
  HolidaySearch,
  HolidaySearchAccommodation,
  HolidaySearchAccommodationSpecial,
  HolidaySearchTour,
} from '../interfaces/holiday/holidaySearch.interface';

import fixArray from '../utils/fixArray';
import { MappingService } from '../services/mapping.service';
import {
  HolidayDetail,
  HolidayDetailAccommodation,
  HolidayDetailSolution,
  HolidayDetailSolutionOperative,
  HolidayDetailSolutionRoom,
  HolidayDetailTourItineraryItemExperience,
  HolidayDetailTour,
  HolidayDetailTourItineraryItem,
  HolidayDetailAccommodationRoom,
} from '../interfaces/holiday/holidaySearchDetail.interface';
import {
  HolidayAccommodation,
  HolidayOperative,
  HolidayPlace,
  HolidaySolution,
  HolidaySolutionRoom,
  HolidaySpecial,
  HolidayTourOperator,
  Tour,
  TourItineraryItem,
  TourItineraryItemExperience,
} from '../interfaces/holiday/holiday.interface';
import { map } from 'rxjs/operators';
// import { HolidayFilter } from '@interfaces/holiday/filters.interface';

export class Holiday {

  public id: string | number;
  public title: string;
  public source: string;
  public description: string;
  public isCustom: boolean;
  public isNetworkHoliday: boolean;
  public isLive: boolean;
  public bookable: boolean;
  public tourOperator: HolidayTourOperator;
  public realTourOperator: HolidayTourOperator;
  public places: HolidayPlace[];
  public type: string;
  public pluginSource: number;
  public specials: HolidaySpecial[];
  public accommodation: HolidayAccommodation;
  public Accomodations_Collection: any;
  public solutions: HolidaySolution[];
  public tour: Tour;
  public logo: string;
  public hasPackets: boolean; //PRK
  public isCustomTitle: boolean;

  constructor(holiday: Holiday = null) {
    if (holiday === null || holiday === undefined) {
      this.createEmptyHoliday();
    } else if (holiday instanceof Holiday) {
      this.setHolidayData(holiday);
    } else {
      throw new Error('Cannot instantiate Holiday on given value');
    }
  }

  // #region INITIALIZER
  private setHolidayData(holiday: Holiday) {

    const hol = cloneDeep(holiday);

    this.id = hol.id;
    this.title = hol.title;
    this.source = hol.source;
    this.isCustom = hol.isCustom;
    this.isNetworkHoliday = hol.isNetworkHoliday;
    this.isLive = hol.isLive;
    this.bookable = hol.bookable;
    this.tourOperator = hol.tourOperator;
    this.realTourOperator = hol.realTourOperator;
    this.places = hol.places;
    this.type = hol.type;
    this.pluginSource = hol.pluginSource;
    this.solutions = hol.solutions;
    this.accommodation = hol.accommodation;
    this.specials = hol.specials;
    this.description = hol.description;
    this.tour = hol.tour;
    this.logo = hol.logo;
    this.hasPackets = holiday.hasPackets; //PRK
    this.isCustomTitle = hol.isCustomTitle;
  }

  // #endregion

  // #region EMPTY INITIALIZERS
  private createEmptyHoliday(): void {
    this.id = null;
    this.title = null;
    this.source = null;
    this.isCustom = null;
    this.isNetworkHoliday = null;
    this.isLive = null;
    this.bookable = null;
    this.tourOperator = null;
    this.realTourOperator = null;
    this.places = [];
    this.type = null;
    this.pluginSource = null;
    this.solutions = [];
    this.specials = [];
    this.description = null;
    this.tour = null;
    this.logo = null;
    this.hasPackets = false;  //PRK
    this.isCustomTitle = null;
  }

  private createEmptySolution(): HolidaySolution {
    return {
      id: null,
      basePrice: null,
      basis: {
        name: null,
        code: null,
      },
      departureDate: null,
      departurePlace: null,
      mkPriceCP: null,
      nights: null,
      operatives: [],
      promoQuotes: null,
      quoteZ: null,
      source: null,
      stopSale: null,
      totalPrice: null,
      isThereNetQuotes: null,
      availability: null,
      occupancy: null,
      rooms: [],
      packet: null
    };
  }

  private createEmptySolutionOperative(): HolidayOperative {
    return {
      arrivalPlace: {
        id: null,
        name: null,
        code: null,
      },
      departureDate: null,
      departurePlace: {
        id: null,
        name: null,
        code: null,
      },
      operativeType: null,
      operativeTypology: null,
    };
  }

  private createEmptyTour(): Tour {
    return {
      id: null,
      active: 0,
      category: null,
      createdAt: null,
      createdBy: null,
      days: null,
      destinations: null,
      details: null,
      difficulty: null,
      itinerary: null,
      languages: null,
      nights: null,
      title: null,
      images: [],
      stops: [],
      tags: [],
      texts: [],
    };
  }

  // #endregion

  // #region FORMATTERS
  private formatHolidaySlimAccommodation(holidaySearchAccommodation: HolidaySearchAccommodation): HolidayAccommodation {
    const accommodationPlace = MappingService.getInstance().getDestinationById(holidaySearchAccommodation.Place);

    const accommodationParentPlace = accommodationPlace?.ParentID
      ? MappingService.getInstance().getDestinationById(accommodationPlace?.ParentID)
      : null;

    const formattedAccommodation: HolidayAccommodation = {
      id: holidaySearchAccommodation.Id,
      rooms: holidaySearchAccommodation.AccommodationRoom_Collection,
      tags: [],
      category: {
        name: holidaySearchAccommodation.Accomodation_Cat,
        id: null
      },
      description: null,
      site: null,
      texts: [],
      videos: [],
      name: holidaySearchAccommodation.Name,
      images: [
        {
          url: holidaySearchAccommodation.Photo,
          path: null,
          progressive: 1,
        },
      ], // TO FORMAT
      ll: Number(holidaySearchAccommodation.Ll),
      lt: Number(holidaySearchAccommodation.Lt),

      place: {
        id: accommodationPlace?.Id,
        name: accommodationPlace?.Name,
        ll: null,
        lt: null,
        parent: accommodationParentPlace
          ? {
              id: accommodationParentPlace.Id,
              name: accommodationParentPlace.Name,
              ll: null,
              lt: null,
              parent: null,
            }
          : null,
      },
      type: holidaySearchAccommodation.Accomodation_Type,
      specials: fixArray(holidaySearchAccommodation.AccommodationSpecial_Collection as HolidaySearchAccommodationSpecial[]).map(
        (special) => ({
          category: special.Category,
          id: special.Id,
          icon: special.Icon,
          type: special.Type,
        }),
      ),
      parent: null,
    };

    return formattedAccommodation;
  }

  private formatHolidaySlimSolutions(solutions: HolidaySearchSolution[]): HolidaySolution[] {
    return solutions.map((solution) => {
      const formattedSolution = this.createEmptySolution();
      const departurePlace = MappingService.getInstance().getDeparturePlaceById(solution?.DeparturePlaceID);
      formattedSolution.id = solution.Id;
      formattedSolution.packet = solution.Packet;
      formattedSolution.basePrice = Number(solution.BasePrice);
      formattedSolution.basis.name = solution.Basis_Collection.Basis.Name;
      formattedSolution.basis.code = solution.Basis_Collection.Basis.Code;
      formattedSolution.departureDate = new Date(solution.DepartureDate);
      formattedSolution.departurePlace = departurePlace
        ? {
            id: departurePlace.Id,
            name: departurePlace.Name,
          }
        : null;
      formattedSolution.isThereNetQuotes = solution.isThereNetQuote;
      formattedSolution.mkPriceCP = Number(solution.MKPriceCP);
      formattedSolution.nights = Number(solution.Nights);
      formattedSolution.promoQuotes = null;
      formattedSolution.quoteZ = null;
      formattedSolution.source = solution.Source;
      formattedSolution.stopSale = solution.StopSale;
      formattedSolution.totalPrice = Number(solution.TotalPrice);

      if (
        !solution?.Operatives_Collection?.Operative ||
        (!Array.isArray(solution?.Operatives_Collection?.Operative) && Object.keys(solution?.Operatives_Collection?.Operative).length === 0)
      ) {
        formattedSolution.operatives = [];
      } else {
        formattedSolution.operatives = this.formatHolidaySlimOperatives(
          fixArray(solution?.Operatives_Collection?.Operative as HolidaySearchSolutionOperative | HolidaySearchSolutionOperative[]),
        );
      }

      return formattedSolution;
    });
  }

  private formatHolidaySlimOperatives(operatives: HolidaySearchSolutionOperative[]): HolidayOperative[] {
    return operatives.map((operative) => {
      const formattedOperative = this.createEmptySolutionOperative();

      formattedOperative.arrivalPlace.id = operative.ArrivalPlace.Id;
      formattedOperative.arrivalPlace.name = operative.ArrivalPlace.Name;
      formattedOperative.arrivalPlace.code = MappingService.getInstance().getDeparturePlaceById(operative.ArrivalPlace?.Id)?.Code;
      formattedOperative.departureDate = new Date(operative.DepartureDate);
      formattedOperative.departurePlace.id = operative.DeparturePlace.Id;
      formattedOperative.departurePlace.name = operative.DeparturePlace.Name;
      formattedOperative.departurePlace.code = MappingService.getInstance().getDeparturePlaceById(operative.DeparturePlace?.Id)?.Code;
      formattedOperative.operativeType = operative.OperativeType;
      formattedOperative.operativeTypology = operative.OperativeTypology;

      return formattedOperative;
    });
  }

  private formatHolidaySlimTour(tour: HolidaySearchTour): Tour {
    const formattedTour = this.createEmptyTour();

    formattedTour.id = tour.id;
    formattedTour.title = tour.title;
    formattedTour.active = 1;
    formattedTour.category = tour.category;
    formattedTour.createdAt = new Date(tour.createdAt);
    formattedTour.createdBy = tour.createdBy;
    formattedTour.days = tour.days;
    formattedTour.nights = tour.nights;
    formattedTour.destinations = fixArray(tour?.destinations)?.map((destination) => ({
      id: null,
      ll: null,
      lt: null,
      name: destination,
      parent: null,
    }));
    formattedTour.details = [];
    formattedTour.difficulty = {
      id: null,
      name: tour.difficulty,
    };
    formattedTour.itinerary = [];
    formattedTour.languages = [];
    formattedTour.images = [tour.coverImageBaseUrl + tour.coverImage];
    formattedTour.stops = orderBy(tour.stops, ['day', 'progressive']);

    return formattedTour;
  }

  private formatHolidayDetailTour(tour: HolidayDetailTour): Tour {
    const formattedTour: Tour = this.createEmptyTour();

    formattedTour.id = tour.id;
    formattedTour.title = tour.title;
    formattedTour.active = 1;
    formattedTour.category = tour.category;
    formattedTour.createdAt = new Date(tour.createdAt);
    formattedTour.createdBy = tour.createdBy;
    formattedTour.days = tour.days;
    formattedTour.nights = tour.nights;
    formattedTour.destinations = fixArray(tour.destination).map((destination) => ({
      id: destination.id,
      ll: Number(destination.ll) || 0,
      lt: Number(destination.lt) || 0,
      name: destination.name,
      parent: MappingService.getInstance().getDestinationById(destination.parentId),
    }));
    formattedTour.details = tour.details.sort((det1, det2) => det1.progressive - det2.progressive);
    formattedTour.difficulty = tour.difficulty;
    formattedTour.itinerary = (fixArray<any>(tour.itinerary)
      ?.map(this.formatHolidayDetailTourItineraryStop.bind(this))
      ?.sort((step1: TourItineraryItem, step2: TourItineraryItem) => step1.day - step2.day) as TourItineraryItem[]);
    formattedTour.languages = tour?.languages?.map((lang) => ({
      id: lang.Id,
      type: lang.Type,
      category: lang.Category,
    }));
    formattedTour.texts = tour.details;
    formattedTour.tags = fixArray(tour.tags).map((tag) => ({
      type: tag.description,
      id: tag.id,
      icon: tag.image,
    }));
    formattedTour.images = fixArray(tour?.medias)?.map(media => media.baseUrl + media.fileName) || [];

    return formattedTour;
  }

  private formatHolidayDetailTourItineraryStop(item: HolidayDetailTourItineraryItem): TourItineraryItem {
    return {
      id: item.id,
      day: item.day,
      title: item.title,
      genericAccommodation: false,
      accommodation: {
        category: item?.accommodation?.category,
        id: item?.accommodation?.id,
        ll: Number(item?.accommodation?.ln) || 0,
        lt: Number(item?.accommodation?.lt) || 0,
        name: item?.accommodation?.name,
      },
      description: item.description,
      breakfast: item.breakfast === 1,
      lunch: item.lunch === 1,
      dinner: item.dinner === 1,
      experiences: fixArray(item?.experiences)?.map(this.formatHolidayDetailTourItineraryStopExperience.bind(this)),
      stops: fixArray(item?.stops)?.map((stop) => ({
        destination: stop.destinationName,
        jsonInfo: stop.jsonInfo,
        ll: Number(stop?.longitude) || 0,
        lt: Number(stop?.latitude) || 0,
        progressive: stop?.progressive,
      })),
      medias: fixArray(item?.medias)?.map((media) => ({
        progressive: media?.progressive,
        title: media?.title,
        url: `${media?.baseUrl}${media?.fileName}`,
      })),
    };
  }

  private formatHolidayDetailTourItineraryStopExperience(
    experience: HolidayDetailTourItineraryItemExperience,
  ): TourItineraryItemExperience {
    return {
      experiencePricing: experience.experiencePricing,
      id: experience.id,
      active: experience.active === 1,
      title: experience.title,
      destinations: fixArray(experience.destinations).map((destination) => ({
        id: destination.id,
        // ll: Number(destination.ll) || 0,
        // lt: Number(destination.lt) || 0,
        ll: 0,
        lt: 0,
        name: destination.name,
        parent: MappingService.getInstance().getDestinationById(destination.parentId),
      })),
      difficulty: experience?.difficulty,
      relatedProducts: fixArray(experience?.experiencePlan?.relatedProducts)?.map((product) => ({
        active: product?.active === 1,
        description: product?.description,
        id: product?.id,
        item: {
          category: product.item?.Category,
          id: product.item?.Id,
          type: product.item?.Type,
        },
      })),
      texts: experience?.experiencePlan?.texts,
      languages: fixArray(experience?.languages)?.map((lang) => ({
        id: lang.Id,
        type: lang.Type,
        category: lang.Category,
      })),
      medias: fixArray(experience?.medias)?.map((media) => ({
        title: media?.title,
        progressive: media?.progressive,
        url: `${media?.baseUrl}${media?.fileName}`,
      })),
      tags: fixArray(experience?.tags)?.map((tag) => ({
        id: tag?.id,
        icon: tag?.image,
        description: tag?.description,
      })),
      terms: {
        voucherAccepted: experience?.tec?.voucherAccepted === 1,
        texts: experience?.tec?.texts,
      },
      top: {
        name: experience?.tourOperator?.name,
        id: experience?.tourOperator?.id,
        urlFull: `${experience?.tourOperator?.baseUrlFull}${experience?.tourOperator?.image}`,
        urlMini: `${experience?.tourOperator?.baseUrlMini}${experience?.tourOperator?.image}`,
      },
    };
  }

  private formatHolidayDetailSolutions(solutions: HolidayDetailSolution[]): HolidaySolution[] {
    const formattedSolutions: HolidaySolution[] = solutions.map((solution: HolidayDetailSolution) => {
      const formattedSolution = this.createEmptySolution();

      formattedSolution.id = solution.Id;
      formattedSolution.availability = solution.Availability;
      formattedSolution.occupancy = solution.Occupancy;
      formattedSolution.basePrice = Number(solution.BasePrice);
      formattedSolution.basis.name = solution.Basis_Collection.Basis.Name;
      formattedSolution.basis.code = solution.Basis_Collection.Basis.Code;
      formattedSolution.departureDate = new Date(solution.DepartureDate);
      formattedSolution.departurePlace = {
        id: solution.DeparturePlaceId,
        name: solution.DeparturePlace,
      };
      formattedSolution.isThereNetQuotes = solution.isThereNetQuote;
      formattedSolution.mkPriceCP = Number(solution.MKPriceCP);
      formattedSolution.nights = Number(solution.Nights);
      formattedSolution.promoQuotes = null;
      formattedSolution.quoteZ = null;
      formattedSolution.source = solution.Source;
      formattedSolution.stopSale = solution.StopSale;
      formattedSolution.totalPrice = Number(solution.TotalPrice);

      if (solution.Packet) {
        formattedSolution.packet = this.formatSolutionPacket(solution.Packet);
      } else {
        formattedSolution.packet = null;
      }

      if (
        !solution?.Operatives_Collection?.Operative ||
        (!Array.isArray(solution?.Operatives_Collection?.Operative) && Object.keys(solution?.Operatives_Collection?.Operative).length === 0)
      ) {
        formattedSolution.operatives = [];
      } else {
        formattedSolution.operatives = this.formatHolidayDetailOperatives(
          fixArray(solution?.Operatives_Collection?.Operative as HolidayDetailSolutionOperative | HolidayDetailSolutionOperative[]),
        );
      }

      if (
        !solution?.Rooms_Collection?.Room ||
        (!Array.isArray(solution?.Rooms_Collection?.Room) && Object.keys(solution?.Rooms_Collection?.Room).length === 0)
      ) {
        formattedSolution.rooms = [];
      } else if (solution?.Rooms_Collection) {
        formattedSolution.rooms = this.formatHolidayDetailRooms(fixArray(solution?.Rooms_Collection?.Room));
      }

      return formattedSolution;
    });

    return formattedSolutions;
  }

  private formatSolutionPacket(packet: any) {
    const formattedPacket = cloneDeep(packet);

    if (formattedPacket.experiences) {
      formattedPacket.experiences = fixArray(formattedPacket.experiences);
      //  Flat experiences...
      formattedPacket.experiences = formattedPacket.experiences
      .map( (rawExperience: any) => {
        const {included, experience} = rawExperience;
        experience.included = included;
        return experience;
      })
      //  FixArrays
      formattedPacket.experiences.forEach( (experience: any) => {
        const propertiesToFix = ['destinations', 'categories', 'languages', 'medias', 'stops', 'tags', 'planningDetails', 'meetingPoints'];
        propertiesToFix.forEach( (property: string) => {
          if (experience[property]) {
            experience[property] = fixArray(experience[property]);
          }
        })
      })
    }

    return formattedPacket;
  }

  private formatHolidayDetailAccommodation(accommodation: HolidayDetailAccommodation): HolidayAccommodation {
    const accommodationParentPlace = accommodation?.Place?.ParentID
      ? MappingService.getInstance().getDestinationById(accommodation.Place?.ParentID)
      : null;

    const formattedAccommodation: HolidayAccommodation = {
      id: accommodation.Id,
      rooms: this.formatHolidayAccommodationRoms(fixArray(accommodation?.AccommodationRoom_Collection?.AccommodationRoom)),
      category: {
        id: accommodation.Accomodation_Cat ? accommodation.Accomodation_Cat.Id : null,
        name: accommodation.Accomodation_Cat ? accommodation.Accomodation_Cat.Name : null,
      },
      description: accommodation.Description,
      site: accommodation.Site,
      name: accommodation.Name,
      images: accommodation?.Photos_Collection
        ? fixArray(accommodation?.Photos_Collection?.Photo)
            ?.map((image) => {
              return {
                url: image?.Url,
                progressive: image?.ordine,
                path: image?.path,
              };
            })
            ?.sort((img1, img2) => img1.progressive - img2.progressive)
        : [],
      ll: Number(accommodation.Ll),
      lt: Number(accommodation.Lt),
      place: {
        id: accommodation.Place?.Id,
        name: accommodation.Place?.Name,
        ll: Number(accommodation.Place?.Ll),
        lt: Number(accommodation.Place?.Lt),
        parent: accommodationParentPlace
          ? {
              id: accommodationParentPlace.Id,
              name: accommodationParentPlace.Name,
              ll: null,
              lt: null,
              parent: null,
            }
          : null,
      },
      type: accommodation.Accomodation_Type?.Type,
      specials: fixArray(accommodation.AccommodationSpecial_Collection?.AccommodationSpecial).map((special) => ({
        category: special.Category,
        id: special.Id,
        icon: special.Icon,
        type: special.Type,
      })),
      tags: fixArray(accommodation.AccommodationTag_Collection?.AccommodationTag).map((tag) => ({
        type: tag.description,
        id: tag.id,
        icon: tag.image,
      })),
      texts: fixArray(accommodation?.Texts_Collection?.Text)
        .map((text) => ({
          description: text.description,
          id: text.id,
          progressive: text.progressive,
          title: text.title,
        }))
        .sort((txt1, txt2) => txt1.progressive - txt2.progressive),
      videos: fixArray(accommodation.Videos_Collection).map((video) => ({
        id: video.id,
        progressive: video.ordine,
        text: video.text,
        title: video.title,
        type: video.type,
        url: video.Url,
      })),
      parent: null
    };

    return formattedAccommodation;
  }

  private formatHolidayAccommodationRoms(rooms: HolidayDetailAccommodationRoom[]) {
    return rooms.map( (room: HolidayDetailAccommodationRoom) => {
      const { id, description, name, progressive, roomType } = room;
      return {
        id, description, name, progressive, roomType,
        roomMedias: fixArray(room.roomMedias),
        roomSpecials: fixArray(room.roomSpecials),
      }
    })
  }

  private formatHolidayDetailRooms(rooms: HolidayDetailSolutionRoom[]): HolidaySolutionRoom[] {
    const formattedRooms: HolidaySolutionRoom[] = rooms.map((room) => {
      return {
        adults: room.AdultsNum,
        children: room.ChildsNum,
        infants: room.InfantsNum,
        availability: room.Availability,
        basis: {
          name: room.Basis ? room.Basis.Name : undefined,
          code: room.Basis ? room.Basis.Code : undefined,
        },
        description: room.Description,
        mkPriceCP: Number(room.MKPriceCP),
        occupants: room.Occupants,
        price: Number(room.Price),
        allotment: room.allotmentNumber,
        source: Number(room.Source),
      };
    });
    return formattedRooms;
  }

  private formatHolidayDetailOperatives(operatives: HolidayDetailSolutionOperative[]): HolidayOperative[] {
    const formattedOperatives: HolidayOperative[] = operatives.map((operative) => {
      const arrivalPlace = MappingService.getInstance().getDeparturePlaceById(operative.ArrivalPlace.Id);
      const departurePlace = MappingService.getInstance().getDeparturePlaceById(operative.DeparturePlace.Id);
      return {
        arrivalPlace: {
          id: arrivalPlace.Id,
          name: arrivalPlace.Name,
          code: arrivalPlace.Code,
        },
        departureDate: new Date(operative.DepartureDate),
        departurePlace: {
          id: departurePlace.Id,
          name: departurePlace.Name,
          code: departurePlace.Code,
        },
        operativeType: operative.OperativeType,
        operativeTypology: operative.OperativeTypology,
      };
    });
    return formattedOperatives;
  }

  // #endregion

  // #region PARSERS

  public fromHolidaySlim(holiday: HolidaySearch): Holiday {
    this.places = [];

    fixArray(holiday?.Places_Collection).forEach((placeId) => {
      const place = MappingService.getInstance().getDestinationById(placeId);
      const parentPlace = MappingService.getInstance().getDestinationById(place?.ParentID);
      this.places.push({
        id: placeId,
        name: place?.Name,
        ll: null,
        lt: null,
        parent: {
          id: parentPlace?.Id,
          name: parentPlace?.Name,
          parent: null,
          ll: null,
          lt: null,
        },
      });
    });

    this.id = holiday.Id;
    this.title = holiday.Title;
    this.source = holiday.Source;
    this.isCustom = holiday.IsCustom;
    this.isNetworkHoliday = holiday.IsNetworkHoliday;
    this.isLive = holiday.IsLive;
    this.bookable = holiday.Bookable;
    this.isCustomTitle = holiday.IsCustomTitle;

    //  Mappatura tourOperator e realTourOperator
    const tourOperator = holiday.TourOperatorID ? MappingService.getInstance().getTourOperatorById(holiday.TourOperatorID) : null;
    const realTourOperator = holiday.RealTourOperatorID ? MappingService.getInstance().getTourOperatorById(holiday.RealTourOperatorID) : null;

    this.tourOperator = {
      id: holiday.TourOperatorID,
      name: tourOperator?.Name || null,
      logoSmall: tourOperator?.baseUrlMini && tourOperator?.image ? tourOperator.baseUrlMini + tourOperator.image : null,
      logoFull: tourOperator?.baseUrlFull && tourOperator?.image ? tourOperator.baseUrlFull + tourOperator.image : null,
    };

    this.realTourOperator = {
      id: holiday.RealTourOperatorID,
      name: realTourOperator?.Name || null,
      logoSmall: realTourOperator?.baseUrlMini && realTourOperator?.image ? realTourOperator.baseUrlMini + realTourOperator.image : null,
      logoFull: realTourOperator?.baseUrlFull && realTourOperator?.image ? realTourOperator.baseUrlFull + realTourOperator.image : null,
    };

    this.type = holiday.Type;
    this.pluginSource = holiday.Plugin_Source;

    if (holiday.Solutions_Collection.Size === 0) {
      this.solutions = [];
    } else {
      this.solutions = this.formatHolidaySlimSolutions(fixArray(holiday.Solutions_Collection.SolutionSlim)).sort(
        (a, b) => a.mkPriceCP - b.mkPriceCP,
      );
    }

    this.hasPackets = this.solutions.some( solution => solution.packet)

    this.accommodation = holiday.Accomodations_Collection?.AccomodationSlim ? this.formatHolidaySlimAccommodation(
      holiday.Accomodations_Collection?.AccomodationSlim as HolidaySearchAccommodation,
    ) : null;
    this.specials = [];
    this.description = null;
    this.tour = holiday.Tour ? this.formatHolidaySlimTour(holiday.Tour) : null;

    return this;
  }

  public fromHolidayDetail(holiday: HolidayDetail): Holiday {
    this.id = holiday.Id;
    this.title = holiday.Title;
    this.source = holiday.Source;
    this.isCustom = holiday.IsCustom;
    this.isLive = holiday.IsLive;
    this.bookable = holiday.Bookable;
    this.description = holiday.Description;
    this.specials = [];

    try {


    this.tourOperator = {
      id: holiday.TourOperatorID,
      name: holiday.TourOperator,
    };

    this.realTourOperator = {
      id: holiday.RealTourOperatorID,
      name: null,
    };

    this.places = [];
    fixArray(holiday.Places_Collection.Place).forEach((place) => {
      this.places.push({
        id: place?.Id,
        name: place?.Name,
        //TODO non c'è!!!
        parent: place?.ParentID ? MappingService.getInstance().getDestinationById(place?.ParentID) : null,
        ll: Number(place?.Ll) ?? null,
        lt: Number(place?.Lt) ?? null,
      });
    });
    this.type = holiday.Type;
    this.pluginSource = holiday.Plugin_Source;
    this.logo = holiday?.Logo;

    if (!holiday?.Solutions_Collection?.Size) {
      this.solutions = [];
    } else {
      this.solutions = this.formatHolidayDetailSolutions(fixArray(holiday.Solutions_Collection.Solution)).sort(
        (a, b) => a.mkPriceCP - b.mkPriceCP,
      );
    }
      this.accommodation = holiday?.Accomodations_Collection?.Accomodation ? this.formatHolidayDetailAccommodation(holiday.Accomodations_Collection.Accomodation) : null;
      this.Accomodations_Collection = holiday?.Accomodations_Collection?.Accomodation;
    this.tour = holiday.Tour ? this.formatHolidayDetailTour(holiday.Tour) : null;

    }
    catch(err) {
      console.log(err);
    }
    return this;
  }

}
