export enum HotelFacility {
'alarm' = 'Sveglia',
'minibar' = 'Minibar',
'modem' = 'Modem',
'payTv' = 'PayTV',
'radio' = 'Radio',
'roomAirConditioning' = 'Aria Condizionata',
'roomTelephone' = 'Telefono in Camera',
'safe' = 'Cassaforte',
'tv' = 'TV',
'tvSat' = 'Tv via satellite',
'wifi' = 'WiFi',
'animals' = 'Animali Ammessi',
'bar' = 'bar',
'busStop' = 'Fermata Autobus',
'nosmoking' = 'Struttura interamente non fumatori',
'parkAuto' = 'Parcheggio',
'shuttleApt' = 'Transfer Aeroportuale',
'solarium' = 'solarium'
}
export type HotelFacilityName = 'alarm' | 'minibar' | 'modem' | 'payTv' | 'radio' | 'roomAirConditioning' | 'roomTelephone' | 'safe' | 'tv' | 'tvSat' | 'wifi' | 'animals' | 'bar' | 'busStop' | 'nosmoking' | 'parkAuto' | 'shuttleApt' | 'solarium';