import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../environments/environment';
import { takeUntil, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CmsService implements OnDestroy {
  private destroy$: Subject<any> = new Subject();

  private api: string;
  private pageTypes: any;
  private siteMap: any;

  private home: any;

  private siteApiKey: string;

  constructor(
    private _http: HttpClient
  ) { 
    this.siteApiKey = 'b79368ab0a61f88d2e16d9683a0ac8da654cf7a0';
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public initialise() {
    console.log("INIT SITE - CMS");
    this.api = `${environment.site}${this.siteApiKey}`;
    this.fetchSitemap()
      .pipe(takeUntil(this.destroy$))
      .subscribe((siteMap: any) => {
        this.siteMap = siteMap ?? null;
      });
    this.fetchPageTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((pageTypes: any) => {
        this.pageTypes = pageTypes ?? null;
      });
    this.fetchHomePage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((home: any) => {
        // console.log("HOME", home);
      });
  }

  public fetchHomePage(): Observable<any> {
    if (this.home) {
      return of(this.home);
    }
    const siteSlim = `${this.api}/homePageSlim`;
    return this._http.get(siteSlim).pipe(
      map((site: any) => {
        if (site.error) throw new Error(site.message);
        const home = site.result.pages.find((x: any) => x.hierarchyLevel === 0);
        return home;
      }),
    );
  }

  public fetchSitemap(): Observable<any> {
    if (this.siteMap) {
      return of(this.siteMap);
    }
    const sitemapApi = `${this.api}/siteMapInformation`;
    return this._http.get(sitemapApi).pipe(
      map((sitemap: any) => {
        if (sitemap.error) throw new Error(sitemap.message);
        return sitemap.result;
      }),
    );
  }

  public fetchPageTypes(): Observable<any> {
    if (this.pageTypes) {
      return of(this.pageTypes);
    }
    const sitemapApi = `${this.api}/site/pageTypes`;
    return this._http.get(sitemapApi).pipe(
      map((pageTypes: any) => {
        if (pageTypes.error) throw new Error(pageTypes.message);
        return pageTypes.result;
      }),
    );
  }

  public getPageById(id: number | string): Observable<any> {
    const pageApi = `${this.api}/pageSlim?pageId=${id}`;
    return this._http.get(pageApi).pipe(
      map((page: any) => {
        if (page.error) throw new Error(page.message);
        return page.result;
      }),
    );
  }

  public getPages(): any {
    if (this.siteMap) {
      return this.siteMap;
    } else {
      this.fetchSitemap().pipe(takeUntil(this.destroy$)).subscribe(siteMap => {
        if (!siteMap) {
          console.log("SITEMAP NOT AVAILABLE");
          return null;
        } else {
          this.siteMap = siteMap;
          return this.siteMap;
        }
      });
    }
   
  }


}
