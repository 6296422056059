import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'modal-gallery',
  templateUrl: './modal-gallery.component.html',
  styleUrls: ['./modal-gallery.component.scss']
})
export class ModalGalleryComponent implements OnInit, OnDestroy {
  
  @Input() images: string[];
  imageChunks: string[][];

  constructor( 
    public activeModal: NgbActiveModal,
    private _modalService: ModalService ) { }

  ngOnInit(): void {

    this.imageChunks = [];

    let imagesToPush;
    while(this.images?.length >= 2) {
      imagesToPush = imagesToPush === 1 ? 2 : 1;
      this.imageChunks.push(this.images.splice(0, imagesToPush));
    }

    //  In caso di numero dispari di immagini this.image ne conterrà ancora una, che sraà pushata per ultima
    if(this.images?.length){
      this.imageChunks.push(this.images);
    }

    this._modalService.onModalOpen();
  }
  
  public closeModal() {
    this.activeModal.dismiss();    
  }
  
  ngOnDestroy(): void {
    this._modalService.onModalClosed();
  }

}
