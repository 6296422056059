import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { MobileService } from 'src/app/services/mobile.service';
import moment from 'moment';
import { DateConverter } from  'src/app/utils/dateConverter';


@Component({
  selector: 'engine-holiday-departure-dates',
  templateUrl: './engine-holiday-departure-dates.component.html',
  styleUrls: ['./engine-holiday-departure-dates.component.scss']
})
export class EngineHolidayDepartureDatesComponent implements OnInit, OnChanges , OnDestroy {

  @Input() departureDates: Date[];
  @Input() forceSingleMonth: boolean = false;
  
  @Output() onNewSelectedDepartureDate = new EventEmitter();

  isMobile: boolean;

  private unsubscribe$ = new Subject;
  
  availableNgbDates: NgbDate[];
  minDate: NgbDate;
  maxDate: NgbDate;

  constructor(
    private _mobileService: MobileService,
    ) { }

  ngOnInit(): void {

    //  Gestione Vista Mobile
    this._mobileService.mobileView$.pipe(takeUntil(this.unsubscribe$)).subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.departureDates && this.departureDates) {      
      //  NB: setAvailableDates deve essere invocata nuovamente ogni volta che cambiano le departureDates
      //  Affinché le date disponibili vengano aggiornate dinamicamente in base agli altri parametri selezionati dall'utente sulla pagina di dettaglio            
      this.setAvailableDates();
    }
  }
  
  //  Converte date disponibili nel formato del datepicker e calcola data minima e massima
  setAvailableDates() {

    //  Conversione data nel formato richiesto dal datePicker
    this.availableNgbDates = this.departureDates
    .sort( (a: Date, b: Date) => a.getTime() - b.getTime() )
    .filter( (date: Date) => moment(date).isAfter(moment()))  // solo date future...
    .map( (jsDate: Date) => DateConverter.jsToNgbDate(jsDate));

    //  Impostazione di data minima e massima
    this.minDate = this.availableNgbDates[0];
    this.maxDate = this.availableNgbDates[this.departureDates.length -1];

  }

  //  Controlla se una specifica data è disponibile.
  //  Metodo invocato dal datePicker, per ogni singola data, in modo da disabilitare quelle non disponibili
  isDisabledDate(dateToCheck: NgbDate): boolean {
    const isDateAvailable = this.availableNgbDates.some( availableDate => {
      return dateToCheck.year === availableDate.year && 
        dateToCheck.month === availableDate.month && 
        dateToCheck.day === availableDate.day
    });
    return !isDateAvailable;
  }

  //  Quando l'utente seleziona una data di partenza
  onSelectedDateChange(newDate: any) {
    const jsSelectedDate = DateConverter.ngbToJsDate(newDate);    
    this.onNewSelectedDepartureDate.emit(jsSelectedDate);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
