import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'durationByDates'
})
export class DurationByDatesPipe implements PipeTransform {

  transform(departureDates: any, ...args: unknown[]): unknown {

    
    const dateFrom = moment(departureDates?.from);
    const dateTo = moment(departureDates?.to);
    
    if(moment(dateFrom).isValid() && moment(dateTo).isValid()){
      const diff = dateTo.diff(dateFrom, 'days') + 1;
      return diff;
    }

    return null;
  
  }
  
}
