import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IEmailParams } from '../interfaces/generic/iemail-params';

@Injectable({
  providedIn: 'root'
})
export class MailerService {

  private readonly apiUrl = environment.apiurl;
  private readonly apiKey = environment.apiKey;

  constructor(private _http: HttpClient) { }

  public sendMail(mailObj: IEmailParams) {
    const url = `${this.apiUrl.replace('apidev', 'api').replace('apipre', 'api')}/index.php/sendemail/${this.apiKey}`;  //NB usa api di produzione
    return this._http.post<any>(url, mailObj).toPromise();
  }
}
