import { Component, OnInit } from '@angular/core';
import { MappingService } from './services/mapping.service';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { LoaderService } from './services/loader.service';
import { CmsService } from './modules/cms/services/cms.service';
import { NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SeoService } from './services/seo.service';
import { environment } from '@env/environment';

@Component({
  selector: 'movingevents-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {

  apiKey: string = environment.apiKey;
  hideHeader: boolean = false;

  constructor(
    private _mappingService: MappingService,
    private _loaderService: LoaderService,
    private _cmsService: CmsService,
    private _router: Router,
    private _seoService: SeoService
    ) {
    registerLocaleData(localeIt);
  }

  ngOnInit() {

    this._seoService.setSiteWideMetaTags();

    this._router.events
    .pipe(
      filter( (routeEvent) => routeEvent instanceof NavigationStart ),
      map( (routeEvent) => routeEvent as NavigationStart)
    )
    .subscribe( (routeEvent: NavigationStart) => {
      this._seoService.setDynamicTags();
      this._seoService.setNoIndex(false);
      this.hideHeader = routeEvent.url.includes('vacanze-gazzetta');
    });

    this._cmsService.initialise();

    const mappingLoaderObject = { message: 'Caricamento del sito in corso...' };
    this._loaderService.addLoad(mappingLoaderObject);

    Promise.all([
      this._mappingService.getMappingHoliday(this.apiKey),
      this._mappingService.getMappingCruise(this.apiKey),
    ])
    .then( results => {
      //  HolidayMapping
      if(results[0]) {
        this._mappingService.setHolidayMapping(results[0]);
      }
      //  CruiseMapping
      if(results[1]) {
        this._mappingService.setCruiseMapping(results[1]);
      }

      this._loaderService.removeLoad(mappingLoaderObject);

    })

  }

}


