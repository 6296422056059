import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatItalianHarboursForView'
})
export class FormatItalianHarboursForViewPipe implements PipeTransform {

  transform(value: any, ...args: any[]) {
    return value.split(" (Italia)")[0];
  }

}