import { Pipe, PipeTransform } from '@angular/core';
import { MappingService } from '../services/mapping.service';

@Pipe({
  name: 'itineraryById'
})
export class ItineraryByIdPipe implements PipeTransform {

  constructor(private _mappingService: MappingService) {}

  transform(itineraryId: number): string {
    if(!itineraryId) return '';
    return this._mappingService.getCruiseItineraryObjectById(Number(itineraryId))?.Name || '-';;
  }

}
