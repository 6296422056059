import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CheckoutStepsComponent } from './components/checkout-steps/checkout-steps.component';
import {CrystalLightboxModule} from '@crystalui/angular-lightbox';
import { ConsensoPrivacyPolicyComponent } from './components/consenso-privacy-policy/consenso-privacy-policy.component';
import { ConsensoAdvertisingComponent } from './components/consenso-advertising/consenso-advertising.component';
import { FormRegistryComponent } from './components/form-registry/form-registry.component';
import { AnyDoubtBoxComponent } from './components/any-doubt-box/any-doubt-box.component';
import {RouterModule} from "@angular/router";
import { GuestsSelectorComponent } from './components/guests-selector/guests-selector.component';
import { DetailGalleryComponent } from './components/detail-gallery/detail-gallery.component';
import { LoaderComponent } from './components/loader/loader.component';
import { FormatItalianHarboursForViewPipe } from 'src/app/pipes/format-italian-harbours-for-view.pipe';
import { ModalGalleryComponent } from './components/modal-gallery/modal-gallery.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleMapsModule } from '@angular/google-maps';
import { FilterByPipe } from 'src/app/pipes/filter-by.pipe';
import { MapWrapperComponent } from './components/map-wrapper/map-wrapper.component';
import { DynamicTourOperatorPipe } from 'src/app/pipes/dynamic-tour-operator.pipe';
import { HarbourByIdPipe } from 'src/app/pipes/harbour-by-id.pipe';
import { FiltersCheckboxComponent } from './components/filters/filters-checkbox/filters-checkbox.component';
import { FiltersPriceSliderComponent } from './components/filters/filters-price-slider/filters-price-slider.component';
import { FiltersOrderbyComponent } from './components/filters/filters-orderby/filters-orderby.component';
import { FilterShipsPerOperatorPipe } from 'src/app/pipes/filter-ships-per-operator.pipe';
import { SearchFiltersCruisePipe } from 'src/app/pipes/search-filters/search-filters-cruise.pipe';
import { NouisliderModule } from 'ng2-nouislider';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ItineraryByIdPipe } from 'src/app/pipes/itinerary-by-id.pipe';
import { SearchFiltersHolidayPipe } from 'src/app/pipes/search-filters/search-filters-holiday.pipe';
import { SearchFiltersHotelPipe } from 'src/app/pipes/search-filters/search-filters-hotel.pipe';
import { FormContactComponent } from './components/form-contact/form-contact.component';
import { FormatAgesStringPipe } from 'src/app/pipes/format-ages-string.pipe';
import { FormatDurationStringPipe } from 'src/app/pipes/format-duration-string.pipe';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { CNewsletterComponent } from './components/c-newsletter/c-newsletter.component';
import { ModalGenericComponent } from './components/modal-generic/modal-generic.component';
import { HolidaySearchFiltersComponent } from '../booking-flow/holiday/holiday-search/holiday-search-filters/holiday-search-filters.component';
import { CruiseSearchFiltersComponent } from '../booking-flow/cruise/cruise-search/cruise-search-filters/cruise-search-filters.component';
import { HotelSearchFiltersComponent } from '../booking-flow/hotel/hotel-search/hotel-search-filters/hotel-search-filters.component';
import { EngineHolidayDestinationsComponent } from '../engine/engine-holidays/engine-holiday-destinations/engine-holiday-destinations.component';
import { EngineHolidayDeparturePlacesComponent } from '../engine/engine-holidays/engine-holiday-departure-places/engine-holiday-departure-places.component';
import { EngineHolidayDepartureDatesComponent } from '../engine/engine-holidays/engine-holiday-departure-dates/engine-holiday-departure-dates.component';
import { EngineCruisesDestinationsComponent } from '../engine/engine-cruises/components/engine-cruises-destinations/engine-cruises-destinations.component';
import { EngineCruisesHarboursComponent } from '../engine/engine-cruises/components/engine-cruises-harbours/engine-cruises-harbours.component';
import { EngineCruisesDepartureDateComponent } from '../engine/engine-cruises/components/engine-cruises-departure-date/engine-cruises-departure-date.component';
import { EngineCruisesShipsComponent } from '../engine/engine-cruises/components/engine-cruises-ships/engine-cruises-ships.component';
import { EngineHotelDatesComponent } from '../engine/engine-hotel/engine-hotel-dates/engine-hotel-dates.component';
import { EngineHotelDestinationComponent } from '../engine/engine-hotel/engine-hotel-destination/engine-hotel-destination.component';
import { DurationByDatesPipe } from 'src/app/pipes/duration-by-dates.pipe';
import { DeparturePlaceByIdPipe } from 'src/app/pipes/departure-place-by-id.pipe';
import { DestinationByIdPipe } from 'src/app/pipes/destination-by-id.pipe';
import { OnImageLoadErrorDirective } from 'src/app/directives/on-image-load-error.directive';
import { DetailDatesComponent } from './components/detail-dates/detail-dates.component';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import { SkeletonSearchFiltersComponent } from './skeletons/skeleton-search-filters/skeleton-search-filters.component';
import { SkeletonDetailDatesComponent } from './skeletons/skeleton-detail-dates/skeleton-detail-dates.component';
import { SkeletonMapComponent } from './skeletons/skeleton-map/skeleton-map.component';
import { SkeletonDetailGalleryComponent } from './skeletons/skeleton-detail-gallery/skeleton-detail-gallery.component';
import { SkeletonDetailHeaderComponent } from './skeletons/skeleton-detail-header/skeleton-detail-header.component';
import { SkeletonSolutionsNavComponent } from './skeletons/skeleton-solutions-nav/skeleton-solutions-nav.component';
import { CancelPageComponent } from './components/cancel-page/cancel-page.component';
import { FilterPacketsPipe } from 'src/app/pipes/filter-packets.pipe';
import { HolidayCheckoutExperiencesDetailsComponent } from '../booking-flow/holiday/holiday-checkout/holiday-checkout-experiences/holiday-checkout-experiences-details/holiday-checkout-experiences-details.component';
import { CheckoutSummaryCardComponent } from './components/checkout-summary-card/checkout-summary-card.component';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { PopupComponent } from './components/popup/popup.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CheckoutStepsComponent,
    ConsensoPrivacyPolicyComponent,
    ConsensoAdvertisingComponent,
    FormRegistryComponent,
    AnyDoubtBoxComponent,
    GuestsSelectorComponent,
    DetailGalleryComponent,
    LoaderComponent,
    FormatItalianHarboursForViewPipe,
    ModalGalleryComponent,
    FilterByPipe,
    MapWrapperComponent,
    DynamicTourOperatorPipe,
    HarbourByIdPipe,
    FiltersCheckboxComponent,
    FiltersPriceSliderComponent,
    FiltersOrderbyComponent,
    FilterShipsPerOperatorPipe,
    SearchFiltersCruisePipe,
    PaginationComponent,
    ItineraryByIdPipe,
    SearchFiltersHolidayPipe,
    SearchFiltersHotelPipe,
    CNewsletterComponent,
    FormContactComponent,
    FormatAgesStringPipe,
    FormatDurationStringPipe,
    ModalGenericComponent,
    HolidaySearchFiltersComponent,
    CruiseSearchFiltersComponent,
    HotelSearchFiltersComponent,
    EngineHolidayDestinationsComponent,
    EngineHolidayDeparturePlacesComponent,
    EngineHolidayDepartureDatesComponent,
    EngineCruisesDestinationsComponent,
    EngineCruisesHarboursComponent,
    EngineCruisesDepartureDateComponent,
    EngineCruisesShipsComponent,
    EngineHotelDatesComponent,
    EngineHotelDestinationComponent,
    DurationByDatesPipe,
    DeparturePlaceByIdPipe,
    DestinationByIdPipe,
    OnImageLoadErrorDirective,
    DetailDatesComponent,
    SkeletonSearchFiltersComponent,
    SkeletonDetailDatesComponent,
    SkeletonMapComponent,
    SkeletonDetailGalleryComponent,
    SkeletonDetailHeaderComponent,
    SkeletonSolutionsNavComponent,
    CancelPageComponent,
    FilterPacketsPipe,
    HolidayCheckoutExperiencesDetailsComponent,
    CheckoutSummaryCardComponent,
    SafeHtmlPipe,
    PopupComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    CrystalLightboxModule,
    NgSelectModule,
    GoogleMapsModule,
    NouisliderModule,
    SwiperModule,
    NgxSkeletonLoaderModule
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    CheckoutStepsComponent,
    FormRegistryComponent,
    ConsensoPrivacyPolicyComponent,
    ConsensoAdvertisingComponent,
    AnyDoubtBoxComponent,
    FormsModule,
    NgbModule,
    NgSelectModule,
    DetailGalleryComponent,
    LoaderComponent,
    FormatItalianHarboursForViewPipe,
    CrystalLightboxModule,
    GuestsSelectorComponent,
    GoogleMapsModule,
    FilterByPipe,
    MapWrapperComponent,
    DynamicTourOperatorPipe,
    HarbourByIdPipe,
    ItineraryByIdPipe,
    FiltersCheckboxComponent,
    FiltersPriceSliderComponent,
    FiltersOrderbyComponent,
    FilterShipsPerOperatorPipe,
    SearchFiltersCruisePipe,
    PaginationComponent,
    SearchFiltersHolidayPipe,
    SearchFiltersHotelPipe,
    FormContactComponent,
    FormatAgesStringPipe,
    FormatDurationStringPipe,
    SwiperModule,
    CNewsletterComponent,
    HolidaySearchFiltersComponent,
    CruiseSearchFiltersComponent,
    HotelSearchFiltersComponent,
    EngineHolidayDestinationsComponent,
    EngineHolidayDeparturePlacesComponent,
    EngineHolidayDepartureDatesComponent,
    EngineCruisesDestinationsComponent,
    EngineCruisesHarboursComponent,
    EngineCruisesDepartureDateComponent,
    EngineCruisesShipsComponent,
    EngineHotelDatesComponent,
    EngineHotelDestinationComponent,
    DurationByDatesPipe,
    DeparturePlaceByIdPipe,
    DestinationByIdPipe,
    OnImageLoadErrorDirective,
    DetailDatesComponent,
    SkeletonDetailDatesComponent,
    SkeletonMapComponent,
    SkeletonDetailGalleryComponent,
    SkeletonDetailHeaderComponent,
    SkeletonSolutionsNavComponent,
    SkeletonSearchFiltersComponent,
    CancelPageComponent,
    FilterPacketsPipe,
    HolidayCheckoutExperiencesDetailsComponent,
    NgxSkeletonLoaderModule,
    CheckoutSummaryCardComponent,
    SafeHtmlPipe,
    PopupComponent,
    ReactiveFormsModule
  ],
  providers: [FormatDurationStringPipe, FormatAgesStringPipe]
})
export class SharedModule {}
