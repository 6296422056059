import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CheckoutStep } from '../../../../interfaces/generic/checkout-step.interface';

@Component({
  selector: 'checkout-steps',
  templateUrl: './checkout-steps.component.html',
  styleUrls: ['./checkout-steps.component.scss'],
})
export class CheckoutStepsComponent implements OnInit {

  @Input() currentStep: CheckoutStep;
  @Input() steps: CheckoutStep[] = [];

  @Output() changeStep: EventEmitter<CheckoutStep> = new EventEmitter<CheckoutStep>();

  constructor() { }

  public onClickOnStep(step: CheckoutStep) {
    // if(step.id === this.currentStep.id) return;
    // if(!step.accessible) return;
    this.changeStep.emit(step);
  }

  ngOnInit(): void {
  }
}
