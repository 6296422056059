import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileService {
  public mobileBreakpoint: number = 992;

  public mobileView$: BehaviorSubject<boolean>;

  constructor(public _breakpointObserver: BreakpointObserver) {
    this.mobileView$ = new BehaviorSubject<any>(false);

    this._breakpointObserver.observe([`(min-width: ${this.mobileBreakpoint}px)`]).subscribe((state: BreakpointState) => {
      this.mobileView$.next(!state.matches);
    });
  }

}
