import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDurationString'
})

export class FormatDurationStringPipe implements PipeTransform {
  transform(durationObject: {days: number, nights: number}): string {
    const { days, nights } = durationObject;    
    if( days && nights) {
      return `${days} giorni (${nights} notti)`;
    }
    else if (days || nights) {
      return  days ? days + " giorni" : nights + " notti";
    }
    return '';
  }
}
