import { Component, Input, OnChanges, EventEmitter, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() itemsPerPage : number;
  @Input() totalItems : number;
  @Input() currentPage : number;

  public numberOfPages: any = 0;

  @Output() onClickOnPage = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.totalItems && this.totalItems) {
      this.numberOfPages = Math.ceil(this.totalItems / this.itemsPerPage);
    }
  }

  public selectPage(pageNumber: number) {
    this.onClickOnPage.emit(pageNumber);
  }

  public nextPage(){
    this.selectPage(this.currentPage + 1)
  }

  public prevPage(){
    this.selectPage(this.currentPage - 1)
  }
}