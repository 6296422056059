import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import defaultMeta from '../values/default-meta.json'

@Injectable({
  providedIn: 'root'
})

export class SeoService {

  constructor(
    private _metaService: Meta,
    private _titleService: Title) { }

  default = defaultMeta;  //  Valori di default per alcuni meta tag

  //  Impostazione del tag noindex
  public setNoIndex(noIndex: boolean) {    
    //  Se il tag c'era ma va rimosso
    if (this._metaService.getTag("content='noindex'") && !noIndex) {
      this._metaService.removeTag("content='noindex'");      
    }
    //  Se il tag non c'era ma va aggiunto
    else if (!this._metaService.getTag("content='noindex'") && noIndex) {
      this._metaService.addTag({name: 'robots', content:'noindex'});
    }
  }

  //  Passato un oggetto contenente le informazioni da inserire nei meta tags si occupa di formattarli e aggiungerli o aggiornarli qualora già presenti
  //  NB: Questo metodo è concepito per i meta tags 'dinamici' che variano al variare della rotta. Quelli uguali per tutto il sito sono gestiti separatamente da meteodo specifico.
  public setDynamicTags(rawTags: { [property: string ]: string} = {}) {
    const metaTags = this.getFormattedDynamicTags(rawTags);
    this._titleService.setTitle(this.getFormattedTitle(rawTags.title, true)); //  <title>...</title>
    metaTags.forEach( (tag: MetaDefinition) => {      
      if ( this._metaService.getTag(`name=${tag.name}`) || this._metaService.getTag(`property='${tag.property}'`)) {
        this._metaService.updateTag(tag);
      } else {
        this._metaService.addTag(tag);
      }
    })
  }

  //  Passato come argomento un oggetto contenente le informazioni da inserire nei meta tags dinamici le formatta e restituisce sotto forma di array di oggetti MetaDefinition (classe richiesta dal metaService di Angular)
  //  Il metodo restiuirà SEMPRE i tag di base title, description ed image. Qualora questi campi non fossero stati passati come argomento, veranno utilizzati quelli di default
  private getFormattedDynamicTags(rawTags: { [property: string ]: string}): MetaDefinition[] {

    const title = this.getFormattedTitle(rawTags.title);    
    const description = rawTags.description || this.default.description;
    const image = rawTags.image || location.origin + this.default.image;

    const formattedTags = [] as MetaDefinition[];

    formattedTags.push( {name: 'description', content: description } ) ;

    //  Open Graph
    formattedTags.push( { property: 'og:title', content: title } );
    formattedTags.push( { property: 'og:description', content: description } ) ;
    formattedTags.push( { property: 'og:image', content: image } );
    formattedTags.push( { property: 'og:url', content: location.href })
        
    //  Twitter
    formattedTags.push( { property: 'twitter:title', content: title } );
    formattedTags.push( { property: 'twitter:description', content: description } ) ;
    formattedTags.push( { property: 'twitter:image', content: image } );

    return formattedTags;

  }

  //  Impostazione dei meta tags 'siteWide', validi per l'intero sito
  //  Vengono gestiti da un metodo che può così essere invocato un'unica volta all'inizializzazione dell'appComponent
  public setSiteWideMetaTags(){
    const siteWideMetaTags = this.getFormattedSiteWideTags();
    this._metaService.addTags(siteWideMetaTags);
  }

  //  Restituisce meta tag siteWide come array di oggetti di tipo MetaDefinition
  private getFormattedSiteWideTags(): MetaDefinition[] {
    return [
      { property: 'og:site_name', content: this.default.siteName },
      { property: 'og:type', content: this.default.contentType },
      { property: 'og:locale', content: this.default.locale },
      { property: 'twitter:card', content: this.default.twitterCardType },
      { property: 'twitter:site', content: "" } //TODO: nome utente del sito su twitter
    ];
  }
  
  //  Restituisce il titolo della pagina, che sarà impiegato sia per il tag <title> sia per vari campi meta
  private getFormattedTitle(providedTitle: string, includeSiteName: boolean = false) {

    //  Se non è stato passato un titolo uso quello di default
    providedTitle = providedTitle || this.default.title 

    //  a prescindere dal parametro includeSiteName passato, faccio in modo che se il titolo contiente già il nome del sito questo non venga mai ripetuto alla fine della stringa
    if ( providedTitle?.toLowerCase().includes(this.default?.siteName?.toLowerCase()) ) {
      includeSiteName = false;
    }
    //  Restituisco il titolo passato come paramtro o, se non disponibile, quello di default, seguito eventualmente dal nome del sito
    return  providedTitle + ( includeSiteName ? ` ${this.default.separator} ${this.default.siteName}`  : '');
  }


}