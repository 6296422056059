import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'filters-price-slider',
  templateUrl: './filters-price-slider.component.html',
  styleUrls: ['./filters-price-slider.component.scss']
})
export class FiltersPriceSliderComponent implements OnInit, OnChanges {
  
  @Input() filterData: any = null;
  @Output() onSelectedPricesChange: EventEmitter<any> = new EventEmitter();

  currentRange: number[] = [0,0];

  constructor() { }

  ngOnInit(): void {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.filterData && this.filterData) {
      this.currentRange[1] = this.filterData?.maxPriceSelected;
      this.currentRange[0] = this.filterData?.minPriceSelected;
      this.currentRange = [...this.currentRange];
    }
  }

  public onPriceChange($event: any) {
    const [ minPriceSelected, maxPriceSelected ] = $event;    
    this.filterData.minPriceSelected = minPriceSelected;
    this.filterData.maxPriceSelected = maxPriceSelected;
    this.onSelectedPricesChange.emit(this.filterData);
  }

}
