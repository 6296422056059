import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterShipsPerOperator'
})
export class FilterShipsPerOperatorPipe implements PipeTransform {

  transform(ships: any, ...args: any): any {

    const filteredCosta = [] as any[];
    const filteredMSC = [] as any[];
    const filteredRoyal = [] as any[];
    const filteredCarnival = [] as any[];
    const otherOperators = [] as any[];
    
    ships.forEach((ship: any) => {
      if (ship?.Name.toLowerCase().includes('costa')) {
        filteredCosta.push(ship);
      } else if (ship?.Name.toLowerCase().includes('msc')) {
        filteredMSC.push(ship);
      } else if (ship?.Name.toLowerCase().includes('royal')|| ship?.Name.toLowerCase().includes('of the seas')) {
        filteredRoyal.push(ship);
      } else if (ship?.Name.toLowerCase().includes('carnival')){
        filteredCarnival.push(ship);
      } else {
        otherOperators.push(ship);
      }
    })

    const formattedShips = [] as any[];
    
    formattedShips.push(
      {
        name: 'Costa',
        ships: filteredCosta,
      },
      {
        name: 'MSC',
        ships: filteredMSC,
      },
      {
        name: 'Royal',
        ships: filteredRoyal,
      },
      {
        name: 'Carnival',
        ships: filteredCarnival,
      },
      {
        name: 'Altre navi',
        ships: otherOperators,
      },
    )

    console.log(formattedShips);
    return formattedShips;

    
  }
}