import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { DateConverter } from  'src/app/utils/dateConverter';

@Component({
  selector: 'engine-hotel-dates',
  templateUrl: './engine-hotel-dates.component.html',
  styleUrls: ['./engine-hotel-dates.component.scss']
})
export class EngineHotelDatesComponent implements OnInit {
  
  @Output() onNewSelectedDates = new EventEmitter();
  
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  minDate: NgbDate;
  maxDate: NgbDate;

  constructor(calendar: NgbCalendar) {}

  ngOnInit(): void {
    this.setLimitDates();
  }

  private setLimitDates() {
    const today = moment();
    this.minDate = DateConverter.jsToNgbDate(today.toDate());
    this.maxDate = DateConverter.jsToNgbDate( today.add(2, 'years').toDate() );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  public onClickonConfirm() {
    this.onNewSelectedDates.emit({ 
      dateFrom: this.fromDate ? DateConverter.ngbToJsDate(this.fromDate) : null,
      dateTo: this.toDate ? DateConverter.ngbToJsDate(this.toDate) : null
    });
  }

}
