export enum ModalsTypologies {    
    OCCUPANCY = 'occupancy',
    INFO = 'error',
    HELP = 'help',

    // HOLIDAY
    HOLIDAY_DESTINATIONS = 'holiday-destinations',
    HOLIDAY_DEPARTURE_PLACES = 'holiday-departure-places',
    HOLIDAY_DEPARTURE_DATES = 'holiday-departure-dates',    
    HOLIDAY_SEARCH_FILTERS = 'holiday-search-filters',
    HOLIDAY_EXPERIENCE_DETAIL = 'holiday-experience-detail',
    
    // HOTEL
    HOTEL_DESTINATIONS = 'hotel-destinations',
    HOTEL_DATES = 'hotel-dates',
    HOTEL_SEARCH_FILTERS = 'hotel-search-filters',


    // CRUISES
    CRUISE_DESTINATIONS = 'cruise-destinations',
    CRUISE_DEPARTURE_PLACES = 'cruise-departure-places',
    CRUISE_DEPARTURE_DATES = 'cruise-departure-dates',
    CRUISE_SHIPS = 'cruise-ships',
    CRUISE_SEARCH_FILTERS = 'cruise-search-filters',

}