import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CruiseMappingHarbour } from 'src/app/interfaces/cruise/cruiseMapping.interface';

@Component({
  selector: 'engine-cruises-harbours',
  templateUrl: './engine-cruises-harbours.component.html',
  styleUrls: ['./engine-cruises-harbours.component.scss']
})
export class EngineCruisesHarboursComponent implements OnInit {

  @Input() harbours: CruiseMappingHarbour[];
  @Output() onNewSelectedHarbours = new EventEmitter();

  public italianHarbours: any[];
  public foreignHarbours: any[];

  public showTooManyelectionError: boolean = false;
  public maxSelectableHarbours: number = 3;

  public selectedHarbours: CruiseMappingHarbour[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
    this.filterHarbours();
  }

  filterHarbours(){
    this.italianHarbours = [] as any[];
    this.foreignHarbours = [] as any[];
    this.harbours.sort((a, b) => a.Name.localeCompare(b.Name));
    this.harbours.forEach(harbour => {
      return (harbour.Name.includes('(Italia)')) ? this.italianHarbours.push(harbour) : this.foreignHarbours.push(harbour);
    });
  }

  public onClickOnHarbour(harbour: CruiseMappingHarbour): void {
    
    const harbourIndexInArray = this.selectedHarbours.findIndex( (selectedHarbour: CruiseMappingHarbour) => selectedHarbour.Id === harbour.Id );
    //  Il porto era già stato selezionato
    if (harbourIndexInArray >= 0) {
      this.selectedHarbours.splice(harbourIndexInArray,1);
      this.hideTooManyelectionError();
      return;
    }    
    
    //  Se il porto non faceva ancora parte della selezione ma è stato raggiunto il numero massimo di porti selezionabili...
    if (this.selectedHarbours.length >= this.maxSelectableHarbours) {
      this.showTooManyelectionError = true;
      return;
    }
    
    this.hideTooManyelectionError();
    this.selectedHarbours.push(harbour);   

  }

  public hideTooManyelectionError() {
    this.showTooManyelectionError = false;
  }
  
  public onClickOnConfirm() {
    if(!this.selectedHarbours.length) return;
    this.onNewSelectedHarbours.emit(this.selectedHarbours);
  }

  public isHarbourSelected(harbour: CruiseMappingHarbour) {
    return this.selectedHarbours.findIndex( (selectedHarbour: CruiseMappingHarbour) => selectedHarbour.Id === harbour.Id ) >= 0;
  }

}