import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'filters-orderby',
  templateUrl: './filters-orderby.component.html',
  styleUrls: ['./filters-orderby.component.scss']
})
export class FiltersOrderbyComponent implements OnInit {

  @Input() filterData: any = null;
  @Output() onSelectedOrderChange: EventEmitter<any> = new EventEmitter();

  selectedOrder : 'priceAsc' | 'priceDesc' | 'distance' | 'featured';

  constructor() { }

  ngOnInit(): void {
    this.selectedOrder = this.filterData.find( (option: any) => option.isSelected ).value;
  }

  public onOrderChange(newSelectedOrder: any) {
    this.filterData.forEach( (option: any) => option.isSelected = false)    
    this.filterData.find( (option: any) => option.value === newSelectedOrder).isSelected = true;
    this.onSelectedOrderChange.emit(this.filterData);
  }

}
