import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { MappingService } from 'src/app/services/mapping.service';
import {HolidayEngineDeparturePlaces} from "@interfaces/holiday/holidayEngine.interface";

@Component({
  selector: 'engine-holiday-departure-places',
  templateUrl: './engine-holiday-departure-places.component.html',
  styleUrls: ['./engine-holiday-departure-places.component.scss']
})
export class EngineHolidayDeparturePlacesComponent implements OnChanges {

  @Input() departurePlaces: string[];
  @Output() onDeparturePlacesSelected = new EventEmitter();

  departurePlacesInner: HolidayEngineDeparturePlaces;

  constructor(private _mappingService: MappingService) { }

  // ngOnInit(): void {
  // }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.departurePlaces && this.departurePlaces?.length) {
      this.departurePlacesInner = this._mappingService.mapDeparturePlaces(this.departurePlaces);
    }
  }

  onClickOnDeparturePlace(departurePlace: any) {
    if(!departurePlace) departurePlace = {Name: "Solo Soggiorno", Id: 'null'};  //  Solo soggiorno
    this.onDeparturePlacesSelected.emit([departurePlace]);
  }

}
