import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CruiseMappingItinerary } from 'src/app/interfaces/cruise/cruiseMapping.interface';

@Component({
  selector: 'engine-cruises-destinations',
  templateUrl: './engine-cruises-destinations.component.html',
  styleUrls: ['./engine-cruises-destinations.component.scss']
})

export class EngineCruisesDestinationsComponent implements OnInit {

  @Input() destinations: CruiseMappingItinerary[];
  @Output() onNewSelectedDestinations = new EventEmitter();

  selectedDestination: CruiseMappingItinerary;

  constructor() { }

  public onClickOnDestination(destination: CruiseMappingItinerary): void {
    this.selectedDestination = destination;
    this.onNewSelectedDestinations.emit([this.selectedDestination]);
  }

  ngOnInit(): void {}


}
