import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CruiseMappingShip } from 'src/app/interfaces/cruise/cruiseMapping.interface';

@Component({
  selector: 'engine-cruises-ships',
  templateUrl: './engine-cruises-ships.component.html',
  styleUrls: ['./engine-cruises-ships.component.scss']
})
export class EngineCruisesShipsComponent implements OnInit {

  @Input() ships: CruiseMappingShip;
  @Output() onNewSelectedShips = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
  }

  //  Al click su una nave
  public onClickOnShip(ship: CruiseMappingShip): void {
    if (!ship) {
      ship = {
        Id: null,
        Name: 'Qualsiasi Nave'
      }
    }
    this.onNewSelectedShips.emit([ship]);
  }

}
