import { environment } from "@env/environment";

export class Hotel {
  
  public static apiKey: string = environment.hotelApiKey;

  public static getPrefetchHotelsQuery(term: string): any {
    return JSON.stringify({
      query: `
        query {
          prefetch (searchParam: \"${term}\") {
            code
            description
            type
            culture
            cityCode
            cityDescription
          }
        }
      `
    })
  }

  public static getSearchHotelsQuery(params: any): any {
    return JSON.stringify({
      query: `
        query {
          search (code: \"${params.destinationCode}\", type: \"${params.type}\", culture: \"${params.culture || 'IT'}\", checkIn: \"${params.dateFrom}\", cityCode: \"${params.cityCode}\",  checkOut: \"${params.dateTo}\", apikey: \"${this.apiKey}\", occupancy: ${params.ages}, childrenAge: [${params.childrenAges ? [...params.childrenAges] : ''}]) {
            name
            checkIn
            checkOut
            nights
            rooms {
              agreementId
              roomTypes {
                type
                occupancy
                required
                price
              }
              roomDescription
              totalPrice
              deadline
              policies {
                from
                percentage
              }
              roomBasis
              mealBasis
              remarks
            }
            hotelDetails {
              name
              code
              stars              
              latitude
              longitude
              cityCode
              city
              hotelFacilities {
                checkin                                
                internet                
                garageAuto
                busStop
                animals
                bar
                nosmoking
                parkAuto
                lifts
                lobbySize
                poolClose
                dinner
                endPorter                
                hairdreyer
                description
                shuttleApt
                solarium                
            }
            roomsFacilities {
              minibar
              tv
              alarm
              safe
              wifi
              roomTelephone
              tvSat
              radio
              modem
              roomAirConditioning
            }         
            position {
              centerDistance
            }
            pictures
          }
        }
      }
    `
    })
  }

  public static getDetailHotelsQuery(params: any): any {
    return JSON.stringify({
      query: `
        query {
          search (code: \"${params.destinationCode}\", type: "hotel", culture: \"${params.culture || 'IT' }\", apikey: \"${this.apiKey}\", checkIn: \"${params.dateFrom}\", checkOut: \"${params.dateTo}\", occupancy: ${params.ages}, childrenAge: [${[...params.childrenAges]}]) {
            name
            checkIn
            checkOut
            nights
            searchNumber
            rooms {
              agreementId
              roomTypes {
                type
                occupancy
                required
                price
              }
              roomDescription
              totalPrice
              deadline
              policies {
                from
                percentage
              }
              roomBasis
              mealBasis
              remarks
            }
            hotelDetails {
                name
                code
                stars                
                latitude
                longitude
                cityCode
                city
                hotelFacilities {
                  checkin                  
                  internet                  
                  busStop
                  animals
                  bar
                  nosmoking
                  parkAuto                  
                  poolOpen
                  projector
                  mic
                  lifts                  
                  poolClose
                  dinner
                  endPorter
                  coachParking
                  hairdreyer
                  description
                  poolHot                  
                  shuttleApt                  
                  solarium
                }
                roomsFacilities {
                  minibar
                  tv
                  alarm
                  safe
                  wifi
                  roomTelephone
                  tvSat
                  radio
                  modem
                  roomAirConditioning
                }
                position {
                  centerDistance
                }
                sheets {
                  txtRooms
                  txtGeneral
                  txtRestaurant
                  txtExterior
                  txtPosition
                  txtLobby
                }
              pictures
          }
        }
      }
    `
    })
  }

  //  Chiamata PreBook
  public static getPreBookingHotelsQuery(params: any): any {
    return { query:` 
              query {
                preBooking (hotelId: \"${params.destinationCode}\", agreementId: \"${params.agreementId}"\, searchNumber: \"${params.searchNumber}\",
                checkIn: \"${params.dateFrom}\", checkOut: \"${params.dateTo}\", childrenAge: [${[...params.childrenAges]}],
                roomType: \"${params.roomType}\", roomsTypes: [${[...params.roomTypes]}], price: \"${params.roomPrice}\", pluginName: "AIC-Hotel",
                passengers: ${params.passengers}, apikey: \"${this.apiKey}\", privacy: ${params.privacyString}) {
                  quotationId
                }
              }`
            };
  }

  //  Chiamata Book (prenotazione effettiva)
  public static getBookingHotelsQuery(params: any): any {
    return { query:` 
              query {
                booking (code: \"${params.destinationCode}\", quotationId: ${params.quotationId}, agreementId: \"${params.agreementId}"\,
                  searchNumber: \"${params.searchNumber}\", checkIn: \"${params.dateFrom}\", checkOut: \"${params.dateTo}\",
                  referenceCode: \"${params.referenceCode}\", childrenAge: [${[...params.childrenAges]}], userEmail: \"${params.userEmail}\",
                  emailTitle: \"${params.emailTitle}\", emailFrom: \"${params.emailFrom}\", emailTo: \"${params.userEmail}\",
                  apikey: \"${this.apiKey}\", passengers: ${params.passengers}, roomType: \"${params.roomType}\", roomsTypes: [${[...params.roomTypes]}], emailBody: ${params.emailBody},)
                  {
                    referenceCode
                    responseUrl      
                  }
              }`
            };
  }

}
