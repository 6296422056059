export enum HotelRoomType {
'SGL' = "Camera singola",
'TSU' = "Doppia a uso singolo",
'TWN' = "Stanza doppia",
'DBL' = "Camera doppia",
'TRP' = "Camera tripla",
'QUD' = "Camera quadrupla",
'FAM' = "Camera famiglia (2 adulti + 2 lettini supplementari)",
}

export type HotelRoomTypeKey = 'SGL' | 'TSU' | 'TWN' | 'DBL' | 'TRP' | 'QUD' | 'FAM';
