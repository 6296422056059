import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

  transform(value: any[], searchParam: string, searchParamValue: any): any {
    if (value?.length > 0) {
      return filter(value, [searchParam, searchParamValue]);
    }
    return [];
  }

}
