import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'skeleton-detail-header',
  templateUrl: './skeleton-detail-header.component.html',
  styleUrls: ['./skeleton-detail-header.component.scss']
})
export class SkeletonDetailHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
