import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { HolidayEngineDestination, HolidayEngineDestinationGroup } from 'src/app/interfaces/holiday/holidayEngine.interface';

@Component({
  selector: 'engine-holiday-destinations',
  templateUrl: './engine-holiday-destinations.component.html',
  styleUrls: ['./engine-holiday-destinations.component.scss']
})
export class EngineHolidayDestinationsComponent implements OnInit {

  @Input() destinations: HolidayEngineDestination[];
  @Output() onNewSelectedDestination = new EventEmitter();

  public destinationsGroups: HolidayEngineDestinationGroup[];
  public shownDestinationsGroupName: string = null;
  public destinationsToShow: HolidayEngineDestinationGroup[] | HolidayEngineDestination[];
  private searchDebounceTimer: any;

  constructor() {}

  ngOnInit(): void {
    this.createDestinationsGroups();
    this.showDestinationsGroups();
  }

  //  Compone i gruppi di destinazioni assegnandoli all'array this.destinationsGroups
  private createDestinationsGroups(): void {
    this.destinationsGroups = [];
    
    const allOtherDestinationsGroup: HolidayEngineDestinationGroup = cloneDeep(this.getNewBlankDestinationGroup('Tutte le Destinazioni', true));  //  conterrà le destinazioni non incluse in alcun gruppo

    //  Ciclo tutte le destinazioni esistenti
    this.destinations.forEach( (destination: HolidayEngineDestination) => {
      const destinationGroupName: any = destination.destinationGroup;
      //  Il matching group è quello il cui name corrisponde al groupname della specifica destinazione.
      //  Se la desinazione non ha un groupName, il gruppo di riferimento sarà l'array allOtherDestinationsGroup
      
      let matchingGroup: HolidayEngineDestinationGroup = destinationGroupName ? 
        this.destinationsGroups.find( (existingGroup: any) => existingGroup.name === destinationGroupName ) :
        allOtherDestinationsGroup;

      //  Se il gruppo che corrisponde al groupName della destinazione non esiste ancora...
      //  ...lo creo e lo inserisco all'interno dell'array dei gruppi
      
      if(!matchingGroup) {
        matchingGroup = cloneDeep(this.getNewBlankDestinationGroup(destinationGroupName));
        this.destinationsGroups.push(matchingGroup);  
      }
      
      //  Adesso che un gruppo di riferimento esiste sicuramente inserisco la destinazione al suo interno
      matchingGroup.destinations.push(cloneDeep(destination));
      //  Se il gruppo non possedeva ancora un'immagine di copertina inserisco quella della destinazione ciclata
      matchingGroup.imgUrl = matchingGroup.imgUrl || destination.imgUrl;
    });

    //  Inserisco anche il gruppo allOtherDestinationsGroup nell'array, a me no che non sia vuoto
    if(!allOtherDestinationsGroup.destinations?.length) return;
    this.destinationsGroups.push(allOtherDestinationsGroup);
    
  }

  //  Metodo 'costruttore' dei destinationGroup: restituisce un nuovo gruppo vuoto avente come nome quello passato come argomento
  getNewBlankDestinationGroup(name: string, isAllOtherDestinationsGroup = false): HolidayEngineDestinationGroup {
    return { name, isAllOtherDestinationsGroup, imgUrl: null, destinations: [] };
  }

  //  Mostra tutti i gruppi di destinazioni
  public showDestinationsGroups(): void {
    this.destinationsToShow = this.destinationsGroups;
    this.shownDestinationsGroupName = null;
  }
  
  //  Quando l'utente fa click su una card (destinazione o gruppo di destinazioni)
  public onClickOnCard(destination: any): void {
    //  Se siamo sulla visualizzazione di un gruppo il click va interpretato come scelta di una destinazione.
    if(this.shownDestinationsGroupName) {
      this.selectDestination(destination);      
      return;
    }
    //  Se siamo sulla visualizzazione iniziale e si è fatto click su uno specifico gruppo di destinazioni passa alla visualizzazione di quel gruppo
    this.onClickOnGroup(destination);
  }

  //  Mostra le sotto-destinazioni di un gruppo, il cui nome viene passato come argomento
  public onClickOnGroup(destinationsGroup: HolidayEngineDestinationGroup): void {
    const {isAllOtherDestinationsGroup, name} = destinationsGroup;
    this.shownDestinationsGroupName = name;
    
    //  A seconda che si sia fatto click su "tutte le destinazioni" o su uno specifico gruppo
    const destinationsToShow = isAllOtherDestinationsGroup ? this.showAllOtherDestinations() : this.showChildrenDestinations(name);

    this.destinationsToShow = destinationsToShow
      .sort( (a: HolidayEngineDestination, b: HolidayEngineDestination, ) => {
        return a.imgUrl ? -1 : 1;
      });
    }
    
    //  Restituisce tutte le sotto-destinazioni appartenenti ad un gruppo, il cui nome viene passato come argomento
    private showChildrenDestinations(selectedGroupName: string): HolidayEngineDestination[] {
      return this.destinations.filter( (destination: HolidayEngineDestination) => destination.destinationGroup === selectedGroupName)
    }

  //  Restituisce tutte le destinazioni non associate ad alcun gruppo
  private showAllOtherDestinations(): HolidayEngineDestination[] {
    return this.destinations.filter( (destination: HolidayEngineDestination) => !destination.destinationGroup)
  }

  //  Seleziona destination passata come argomento
  private selectDestination(destination: HolidayEngineDestination): void {
    this.onNewSelectedDestination.emit(destination);
  }

  public searchInput: string = '';

  public findDestination: any = [];

  public filtersOnSearchDestinations() { 
    this.findDestination = this.destinations
    .filter((destination: HolidayEngineDestination) => {
      return destination.name.toLowerCase().includes(this.searchInput.toLowerCase());
    })
    .sort((a: HolidayEngineDestination, b: HolidayEngineDestination) => a.name.toLowerCase().startsWith(this.searchInput.toLowerCase()) ? -1 : 1); 
  }

  public onSearchFilterChange() {
    clearTimeout(this.searchDebounceTimer);
    this.searchDebounceTimer = setTimeout(() => {
      this.filtersOnSearchDestinations();
    }, 200);
  }


}
