import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../services/common.service';

@Pipe({
  name: 'formatAgesString'
})

export class FormatAgesStringPipe implements PipeTransform {

  constructor(private _commonService: CommonService){}

  transform(agesArray: number[], stringType: 'standard' | 'ADTCHD' | 'withChildrenAges' = 'standard'): string {
    
    const occupancy = this._commonService.getOccupancyFromAgesArray(agesArray);
    
    let adultsCount = occupancy.adults?.length || 0;
    let childrenCount = occupancy.children?.length || 0;

    //  Se il type passato è 'ADTCHD'
    //  l'output sarà del formato "2 ADT, 1 BAM"
    if(stringType === 'ADTCHD') {
      return `${adultsCount} ADT${childrenCount ? ', ' + childrenCount + ' BAM' : ''}`;
    }
    

    let ageString = `${adultsCount} adult${adultsCount === 1 ? 'o' : 'i'}`;
    if (childrenCount) ageString += ` e ${childrenCount} bambin${childrenCount === 1 ? 'o' : 'i'}`;

    //  Se il type è 'standard'
    //  l'output sarà del tipo: 2 adulti e 1 bambino
    if(stringType === 'standard') return ageString;

    //  Se il type passato è 'withChildrenAges' formatto la stringa in modo tale da restituire anche le età dei bambini
    //  l'output sarà del formato "2 adulti e 1 bambino di 8 anni", "1 adult0 e 2 bambino di 4 e 5 anni", "2 adulti e 3 bambini di 12, 15 e 17 anni"...
    if (childrenCount && stringType === 'withChildrenAges') {
      ageString += ` di`;
      for(let i=0; i<occupancy.children.length; i++ ) {        
        if( i !== 0 && i !== occupancy.children.length - 1) {
          ageString += ','
        }        
        if( i === occupancy.children.length - 1 && i !==0 ) {
          ageString += ' e'        
        }
        ageString += ' ' + occupancy.children[i];   
        
        ageString += occupancy?.children[i] === 1 ? ' anno' : ' anni';
      }
    }

    return ageString;
    
  }

}
